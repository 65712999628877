/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-in-header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sign-in-header.component";
var styles_SignInHeaderComponent = [i0.styles];
var RenderType_SignInHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignInHeaderComponent, data: {} });
export { RenderType_SignInHeaderComponent as RenderType_SignInHeaderComponent };
export function View_SignInHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "navbar-fixed"], ["style", "height:90px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "nav", [["class", "nav-wrapper-first"], ["style", "height:90px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "brand-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "sidenav-icon left-logo"], ["src", "assets/img/ministry-of-interial.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ul", [["class", "right hide-on-med-and-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "right hide-on-med-and-down sidenav-icon right-logo"], ["src", "assets/img/hussantuk.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "right hide-on-med-and-down "]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "etisalat-logo"], ["src", "assets/img/etisalat_logo.png"]], null, null, null, null, null))], null, null); }
export function View_SignInHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sign-in-header", [], null, null, null, View_SignInHeaderComponent_0, RenderType_SignInHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.SignInHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInHeaderComponentNgFactory = i1.ɵccf("sign-in-header", i2.SignInHeaderComponent, View_SignInHeaderComponent_Host_0, {}, {}, []);
export { SignInHeaderComponentNgFactory as SignInHeaderComponentNgFactory };
