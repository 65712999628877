<header></header>
<nav mat-tab-nav-bar  style="background-color: grey;position: relative;margin-top: 1%;height: 60px;">
  
    <a mat-tab-link   *ngFor="let link of links"
       (click)="onClick(link)"
       [active]="activeLink == link">
       <p class="textdec"> {{ link }} </p>
    </a>
</nav>

<notifier-container></notifier-container>
<div class="container" *ngIf="!success && success != null">
<div *ngIf="activeLink == 'Upload Customer' ">
    <app-upload-customer (complete)="onFileComplete($event)"></app-upload-customer>
</div>

<div *ngIf="activeLink == 'Update Status' && !success">
    <div class="row" style="margin-top:2%;">
        <div class="col-sm-3" style="margin-left: 15%">
    <p class="textHeading2">Select the status</p>
    </div>
   
  <div class="col-sm-3">
    <mat-form-field appearance="fill" >
        <mat-label >Select Status</mat-label>
        <mat-select  [(ngModel)]="statusSelected" (ngModelChange)="updateStatus()">
          <mat-option *ngFor="let status of statusValues" [value]="status">{{status}}</mat-option>
        </mat-select>
        
    </mat-form-field>
    <p class="textHeading3" *ngIf="statusSelected != 'null'">Your current selection is {{statusSelected}}.</p>
    <p class="textHeading3" *ngIf="statusSelected == 'null'">Your current selection is {{statusSelected}}.</p>
  
  </div>
  <div class="col-sm-1">
    </div>
  <div class="col-sm-3">
    <button (click)="enableUpload()" style="color:green" *ngIf="statusSelected != 'null'"> Confirm for {{statusSelected}}</button>
  </div>
    <app-upload-customer  (complete)="onFileComplete($event)"  [status]="statusSelected" forStatusChange="true" *ngIf="statusConfirmed"></app-upload-customer>
    <img src="assets/correctImage.png" *ngIf="success" alt="Done" height="400" width="400" style="margin-left:35%;margin-right:10%" >

</div>

</div>
</div>


<div *ngIf="activeLink == 'Remove beneficiary' && !success">
    <app-upload-customer   status="ceased" forStatusChange="true" (complete)="onFileComplete($event)"></app-upload-customer>
</div>

<img src="assets/correctImage.png" *ngIf="success" alt="Done" height="400" width="400" style="margin-left:35%;margin-right:10%" >




