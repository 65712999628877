import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AppUserAuth } from '../auth/app-user-auth';
import { trigger, transition, style, animate } from '@angular/animations';
import { fade } from '../animation/animation';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  animations: [
    fade
  ]
})
 


export class SignInComponent implements OnInit {
 
  isLoginError: boolean = false;

  isLoginCount5: boolean = false;

  codedUserGroup;
  userGroup;


  tryingToLoginCount = 0

 

  languageList = [

    { code: 'en', label: 'English' },
    { code: 'ar', label: 'عربى' },

  ];
  textDir: string;



  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute, @Inject(LOCALE_ID) protected localeId: string ,public translate: TranslateService) {

    this.translate.setDefaultLang('ar');
    this.textDir = 'rtl';

   }

  ngOnInit() {
  

  }
hide = true;
loginForm: FormGroup = new FormGroup({
      username: new FormControl('', [Validators.required ]),
      password: new FormControl('', [Validators.min(3) ])
      // the rest of inputs with the same approach
    });

 get usernameInput() { return this.loginForm.get('username'); }
 get passwordInput() { return this.loginForm.get('password'); }


  OnSubmit(credentials) {
  

    this.tryingToLoginCount++;

    if (this.tryingToLoginCount >= 5) {

      this.isLoginCount5 = true;
      this.isLoginError = false;

    } else {

      this.userService.userAuthentication(credentials).subscribe((response: any) => {

        localStorage.setItem(btoa('userToken'), btoa(response.token));

        localStorage.setItem(btoa("userGroup"), btoa(response.group));

        this.codedUserGroup = localStorage.getItem(btoa("userGroup"))
        this.userGroup = atob(this.codedUserGroup)

        this.loginSuccess()

      },

        (err: HttpErrorResponse) => {
          this.isLoginError = true;
        });


    }

  }





  loginSuccess() {


    var returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    //onsole.log(returnUrl)
    //this.router.navigate([returnUrl || 'home/customer/upload'])

    if (this.userGroup == 'documentview' ||this.userGroup == "admin" || this.userGroup == "non_admin" || this.userGroup == "upload"|| this.userGroup == "etisalat" || this.userGroup == "moi" || this.userGroup == "sharjah") {
      if(this.userGroup == 'upload'){
        this.router.navigate([returnUrl || '/admin/upload']);
      }
      else{
      this.router.navigate([returnUrl || '/home/etisalat/upload']);
      }
      

    }

    else {

      this.isLoginError = true;
    }
  }


}
