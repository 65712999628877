import { Component, OnInit ,ChangeDetectorRef , AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  selectedLang = "العربيه";
  userClaims: any;
  codedUserGroup=localStorage.getItem(btoa("userGroup"));
	userGroup=atob(this.codedUserGroup);
  textDir: string;
  constructor(private cdr: ChangeDetectorRef,public translate: TranslateService,private router: Router, private userService: UserService,private location:Location) { 
    translate.addLangs(["en", "ar"]);
    const browserLang = translate.getDefaultLang();
    console.log("Browser Language  header", browserLang);


  }
  
  ngAfterViewChecked(){
    //your code to update the model
    this.cdr.detectChanges();
 }
 
  ngOnInit() {
    /*
    this.userService.getUserClaims().subscribe((data: any) => {
      this.userClaims = data;
    });*/
  
}

switchLanguage() {
    
  if (this.selectedLang == "english"){
    this.translate.setDefaultLang('en');
 
    
  

    console.log( this.translate.getBrowserLang() + "sould be english");
   // window.location.reload();

  }else if (this.selectedLang != "english") {

    this.translate.setDefaultLang('ar');


    console.log( this.translate.getBrowserLang() + "should be arabic ");
   // window.location.reload();

  }
}


  Logout() {
    localStorage.removeItem(btoa('userToken'));
    localStorage.removeItem(btoa('userGroup'));
    this.location.replaceState('/login');
    this.router.navigate(['/login']);
  }

}
