<sign-in-header></sign-in-header>

<div *ngIf="isLoginCount5" class="red-text center error-message" i18n>

  <meta http-equiv="refresh" content="60">

</div>

<div class="fullscreen">
  <div class="container">
    <div class="row">
      <div class="col s6 offset-s4">
        <div >
         <!--  <div class="logo"></div> -->
          <div class="login-start">
            <ul class="tabs tabs-fixed-width tabs-transparent">
              <li class="tab">
                <a routerLink='/login' routerLinkActive='active' i18n class="my-heading">MOCD Customer Data Management</a>
              </li>
            </ul>
            <!--LogIn Form-->
            <div class="card-content">
              <div class="row">

                <div *ngIf="isLoginError" class="red-text center error-message" i18n>
                  <i class="material-icons">error </i> Incorrect username or password
                </div>

                <div *ngIf="isLoginCount5" class="red-text center error-message" i18n>
                  <i class="material-icons">error </i> you tried to login more than 5 times please wait for 60 second
                </div>
 <form  [formGroup]="loginForm"  (ngSubmit)="OnSubmit(loginForm.value)">
  

  <mat-form-field class="row_align input-field col s8 offset-s3" appearance="none">
                     
  <input matInput  type="text" class="login_text login_align"  formControlName="username"   name="username" required >
  <label  i18n class="login_align">User Name</label>
                  
  </mat-form-field>
  


 <mat-form-field class="row_align input-field col s8 offset-s3" appearance="none">
      <input matInput class="login_align" [type]="hide ? 'password' : 'text'" formControlName="password" required>
      <mat-icon class="eye_pos" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      
      <label i18n class="login_align">Password</label>
  </mat-form-field>

                  <div class="row">
                    <div class="input-field col s8 offset-s3">
                      <button style="color: white;" mat-button matStepperNext [disabled]="!loginForm.valid || isLoginCount5" class="button-small" type="submit" i18n> Login</button>
                     
                    </div>
                  </div>
                  <a href="/changepassword" style="margin-left:35%">Forgot Password?</a>


</form>

 <!--                <form #loginForm="ngForm" class="col s12 transparent" (ngSubmit)="OnSubmit(loginForm.value)">
                  <div class="row">
                    <div class="input-field col s12">
                     
                      <input type="text" #UserName ngModel name="username" required class="login_text">
                      <label for="icon_prefix" i18n>User Name</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s12">
                     
                      <input id="icon_prefix" type="password" #Password ngModel name="password" required>
                      <label for="icon_prefix" i18n>Password</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="input-field col s8 offset-s3">
                      <button [disabled]="!loginForm.valid || isLoginCount5" class="button-small" type="submit" i18n> Login</button>
                    </div>
                  </div>

              

                </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>