import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { UserService } from "../shared/user.service";
import 'rxjs/add/operator/do';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    codedUserToken;
    userToken;


    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.codedUserToken = localStorage.getItem(btoa('userToken'))
        this.userToken = atob(this.codedUserToken);



        if (req.headers.get('No-Auth') == "True")

            return next.handle(req.clone());


        else if (this.userToken != 'ée') {

            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Token " + this.userToken)

            });
            return next.handle(clonedreq)
                .do(
                    succ => { },
                    err => {
                        if (err.status === 401)
                            this.router.navigateByUrl('/login');



                    }
                );
        }

    }
}