import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(userService, router, route, localeId, translate) {
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.localeId = localeId;
        this.translate = translate;
        this.isLoginError = false;
        this.isLoginCount5 = false;
        this.tryingToLoginCount = 0;
        this.languageList = [
            { code: 'en', label: 'English' },
            { code: 'ar', label: 'عربى' },
        ];
        this.hide = true;
        this.loginForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.min(3)])
            // the rest of inputs with the same approach
        });
        this.translate.setDefaultLang('ar');
        this.textDir = 'rtl';
    }
    SignInComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(SignInComponent.prototype, "usernameInput", {
        get: function () { return this.loginForm.get('username'); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInComponent.prototype, "passwordInput", {
        get: function () { return this.loginForm.get('password'); },
        enumerable: true,
        configurable: true
    });
    SignInComponent.prototype.OnSubmit = function (credentials) {
        var _this = this;
        this.tryingToLoginCount++;
        if (this.tryingToLoginCount >= 5) {
            this.isLoginCount5 = true;
            this.isLoginError = false;
        }
        else {
            this.userService.userAuthentication(credentials).subscribe(function (response) {
                localStorage.setItem(btoa('userToken'), btoa(response.token));
                localStorage.setItem(btoa("userGroup"), btoa(response.group));
                _this.codedUserGroup = localStorage.getItem(btoa("userGroup"));
                _this.userGroup = atob(_this.codedUserGroup);
                _this.loginSuccess();
            }, function (err) {
                _this.isLoginError = true;
            });
        }
    };
    SignInComponent.prototype.loginSuccess = function () {
        var returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        //onsole.log(returnUrl)
        //this.router.navigate([returnUrl || 'home/customer/upload'])
        if (this.userGroup == 'documentview' || this.userGroup == "admin" || this.userGroup == "non_admin" || this.userGroup == "upload" || this.userGroup == "etisalat" || this.userGroup == "moi" || this.userGroup == "sharjah") {
            if (this.userGroup == 'upload') {
                this.router.navigate([returnUrl || '/admin/upload']);
            }
            else {
                this.router.navigate([returnUrl || '/home/etisalat/upload']);
            }
        }
        else {
            this.isLoginError = true;
        }
    };
    return SignInComponent;
}());
export { SignInComponent };
