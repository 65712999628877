import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { UploadService } from '../shared/multi-upload.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { concat } from 'rxjs';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material'
import { MyDialogComponent } from '../my-dialog/my-dialog.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { trigger, transition, style, animate, state } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'util';
import { Console } from '@angular/core/src/console';
import { UserService } from '../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-upload-multiple',
  templateUrl: './upload-multiple.component.html',
  styleUrls: ['./upload-multiple.component.css'],

  animations: [
    trigger(
      'fadeIn', [
        transition(
          'void => *', [

            style({ opacity: 0, color: 'green' }), animate(2000)

          ]
        ),
        transition(
          '* => void', [

            style({ opacity: 1 }), animate(0)

          ]
        )
      ]
    )
  ]
})

export class UploadMultipleComponent implements OnInit {



  DJANGO_SERVER = 'http://localhost:8092';
  hide: boolean;
  @Input() emiratesId;

  @Input() userId;

  @Input() selectedLang;
  isShowFiles =  true;

  @Input() uploaderType;
  loading_gif: boolean;

  static countUploadedF: number = 0;

  successMessageEn : string  = 'your file has been uploaded successfully'
  successMessage : string  = 'your file has been uploaded successfully'
  successMessageAr : string  = 'تم رفع الملفات بنجاح'

  public uploader: FileUploader = new FileUploader({});
  textDir: string;
  //public hasBaseDropZoneOver: boolean = false;
  constructor(@Inject(LOCALE_ID) protected localeId: string, private userService: UserService, public translate: TranslateService ,private uploadService: UploadService, private location: Location, private snackBar: MatSnackBar, public dialog: MatDialog, private router: Router) { 

    translate.addLangs(["en", "ar"]);

    if (this.translate.getDefaultLang() == 'ar'){

      this.successMessage = this.successMessageAr;
    
      this.textDir = 'rtl';
      
    }else {

      this.successMessage = this.successMessageEn
      this.textDir = 'ltr';
    }

    let codedUserGroup = localStorage.getItem(btoa("userGroup"))
    let userGroup = atob(codedUserGroup)

    if(userGroup == 'documentview'){
      this.isShowFiles =  false;
    }

   
  }

  // fileOverBase(event):  void {
  //   this.hasBaseDropZoneOver  =  event;
  //}

  getFiles(): FileLikeObject[] {
    
    return this.uploader.queue.map((fileItem) => {
      //console.log(fileItem,fileItem.file)
      return fileItem.file;
    });
  }
  upload() {
    let files = this.getFiles();
    var queueLength = files.length
    if (queueLength > 0) {
      this.loading_gif = true;
    }
    console.log(this.uploader)
    let requests = [];
    files.forEach((file) => {
      let formData = new FormData();
      formData.append('file', file.rawFile, file.name);
      requests.push(this.uploadService.upload(formData, this.userId));
    });

    concat(...requests).subscribe(
      (res) => {



        queueLength = queueLength - 1

        if (queueLength == 0) {
          this.loading_gif = false;
          if (this.uploaderType == "customer") {
            this.hide = true;
            UploadMultipleComponent.countUploadedF += 1;

          }

          //this.openModal();

        }

        requests.splice(0, requests.length);

        this.uploader.clearQueue()
        this.openSnackBar(this.successMessage)

        if (UploadMultipleComponent.countUploadedF == 3) {
          UploadMultipleComponent.countUploadedF = 0;
          console.log("Before calling complete thanks ",this.emiratesId);
          this.userService.putChangeStatus(this.userId, "pending approval (New documents)").subscribe((data: any) => {
              console.log("Status successfully updated to Pending File Verification");
          },
            (err: HttpErrorResponse) => {
              console.log(err);
            });
          this.router.navigate(['/completed/thanks']);

        }




      },
      (err) => {
        console.log(err);
        console.log("File validation errors");
        this.loading_gif = false;
        this.openSnackBar("Please recheck the file format, supported format are png/jpg")
        //error
      }


    );
  }
  delete(i) {


    this.uploader.queue.splice(i, 1)


  }
  deleteAll() {


    this.uploader.queue.splice(0, this.uploader.queue.length)
    //console.log(this.uploader.queue,i)

  }
  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, { duration: 2000, });
  }

  reUpload() {
    this.hide = !this.hide;
    UploadMultipleComponent.countUploadedF -= 1;
  }
  ngOnInit() {
  }

  //openModal() {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.disableClose = true;
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.data = {
  //    id: 1,
  //    title: 'Your File/s successfully uploaded!',
  //    content: 'Click Continue to upload more files or finish to end the task.'
  //
  //  };
  //  const dialogRef = this.dialog.open(MyDialogComponent, dialogConfig);
  //  dialogRef.afterClosed().subscribe(result => {
  //    // console.log("Dialog was closed")
  //
  //    if (result) {
  //
  //
  //    }
  //    else {
  //
  //      if (this.uploaderType == "customer") {
  //
  //        //  this.location.replaceState('/completed/thanks')
  //        this.router.navigate(['/completed/thanks']);
  //
  //      }
  //
  //    }
  //
  //  });
  //}

}
