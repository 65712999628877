import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { UserService } from './shared/user.service';
import { HeaderComponent } from './header/header.component';
import { SignInHeaderComponent } from './sign-in-header/sign-in-header.component';
import { SidenavbarComponent } from './sidenavbar/sidenavbar.component';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import { UploadMultipleComponent } from './upload-multiple/upload-multiple.component';
import { MoiCustomersComponent } from './moi-customers/moi-customers.component';
import { AnonymousUserComponent } from './anonymous-user/anonymous-user.component';
import { CustomersTableComponent } from './customers-table/customers-table.component'
import { MatDialogModule, MatButtonModule, MatTableModule, MatSnackBarModule, MatTabsModule, MatSortModule, MatSelectModule, MatPaginatorModule, MatDialog, MatDialogConfig, MatProgressBarModule, MatProgressSpinnerModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { RecaptchaModule } from 'ng-recaptcha';
import { ShowImageComponent } from './show-image/show-image.component';
import { ClickOutsideMeDirective } from './click-outside-me.directive'
import { ThanksComponent } from './thanks/thanks.component';
import { NotFoundComponent } from './not-found/not-found.component'
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar-EG';
import { StatusCommentComponent } from './status-comment/status-comment.component';
import { MyDialogComponent } from './my-dialog/my-dialog.component';
import { CommentsComponent } from './comments/comments.component';
import { ImageViewerModule } from "ngx-image-viewer";
import { ErrorsHandler } from './errorHandling/ErrorsHandler';
import { FlexLayoutModule } from '@angular/flex-layout';
import { from } from 'rxjs';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ShowPdfComponent } from './show-pdf/show-pdf.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DialogOverviewExampleDialogActionsSecurity } from './moi-customers/moi-customers.component';
import { AdminModuleComponent } from './admin-module/admin-module.component';
import { UploadCustomerComponent } from './upload-customer/upload-customer.component';
registerLocaleData(localeAr, 'ar');

const customNotifierOptions: NotifierOptions = {
  position: {
        horizontal: {
            position: 'right',
            distance: 6
        },
        vertical: {
            position: 'top',
            distance: 250,
            gap: 10
        }
    },
  theme: 'material',
  behaviour: {
    autoHide: 30000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 2
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignInComponent,
    SignInHeaderComponent,
    HeaderComponent,
    SidenavbarComponent,
    UploadMultipleComponent,
    MoiCustomersComponent,
    AnonymousUserComponent,
    CustomersTableComponent,
    ShowImageComponent,
    ClickOutsideMeDirective,
    ThanksComponent,
    NotFoundComponent,
    StatusCommentComponent,
    MyDialogComponent,
    CommentsComponent,
    ShowPdfComponent,
    ChangePasswordComponent,
    DialogOverviewExampleDialogActionsSecurity,
    AdminModuleComponent,
    UploadCustomerComponent,
    
  ],
  imports: [
    BrowserModule,
    CustomMaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpModule,
    CommonModule,
    FileUploadModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDividerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxExtendedPdfViewerModule, 
    NotifierModule.withConfig(customNotifierOptions),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }) ,
    
    MatButtonModule,
    RecaptchaModule.forRoot(),
    ImageViewerModule.forRoot({
      btnClass: 'font-awesome', // The CSS class(es) that will apply to the buttons
      zoomFactor: 0.1, // The amount that the scale will be increased by
      containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
      wheelZoom: true, // If true, the mouse wheel can be used to zoom in
      allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
      allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
      btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
        zoomIn: 'fa fa-plus',
        zoomOut: 'fa fa-minus',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
        next: 'fa fa-arrow-right',
        prev: 'fa fa-arrow-left',
        fullscreen: 'fa fa-arrows-alt',
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: true,
        rotateCounterClockwise: true,
        next: true,
        prev: true
      }
    })

  ],
  entryComponents: [
    MyDialogComponent,
    DialogOverviewExampleDialogActionsSecurity
  ],
  providers: [UserService, AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    }
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {

}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


