<button mat-button color="warn" (click)="onClick()" class="uploadBtn" style="margin-top:10%;margin-left:30%;align-content:center">
    <!-- <mat-icon>file_upload</mat-icon> -->
    <img src="assets/img/upload_2.png" width=40% height=40%  class="upload_image"/> 
    
   
</button>
<br/>
<p class="labelTest" style="margin-top:2%;margin-left:30%;align-content:center" > Browse to upload Customer XLSX</p>
<ul>
    <li *ngFor="let file of files" [@fadeInOut]="file.state">
          <mat-progress-bar [value]="file.progress" style="margin-left:20%;width: 50%"></mat-progress-bar>
          <span id="file-label" style="margin-left:20%;width: 50%">
          {{file.data.name}} 
          <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry" style="margin-left:20%;width: 50%">
          <mat-icon style="margin-left:20%;width: 50%">refresh</mat-icon></a>
          <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel" style="margin-left:20%;width: 50%">
          <mat-icon style="margin-left:20%;width: 50%">cancel</mat-icon></a>
          </span>
    </li>
</ul>
<input type="file" id="fileUpload" name="fileUpload" multiple="multiple" 

accept="{{accept}}" style="display:none;"/>

<table mat-table [dataSource]="dataSource"  style="width: 100%;margin-left:20%" *ngIf="!success || success != 'Not Initialized'">

    <!-- CASE ID Column -->
    <ng-container matColumnDef="case_id">
      <th mat-header-cell *matHeaderCellDef class="textHeading2"> CASE ID </th>
      <td mat-cell *matCellDef="let element" class="textHeading3"> {{element.case_id}} </td>
    </ng-container>

    <!-- ERROR MESSAGE Column -->
    <ng-container matColumnDef="error_message">
      <th mat-header-cell *matHeaderCellDef class="textHeading2"> ERROR MESSAGE  </th>
      <td mat-cell *matCellDef="let element" class="textHeading3"> {{element.error_message}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
  </table>


<img src="assets/correctImage.png" *ngIf="success &&  success != 'Not Initialized'" alt="Done" height="35%" width="35%" style="margin-bottom: 2%">

  
