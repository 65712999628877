<div *ngIf="isShowFiles">
<div [@fadeIn]="hide" *ngIf="hide" dir="{{textDir}}">
    <p i18n>{{'Customer.Thanks_Your_file_has_been_successfully_uploaded' | translate}}<br> <a (click)=reUpload() >{{'Customer.re-upload' | translate}}</a></p>
</div>


<div [style.display]="hide ? 'none':'block'" [@fadeIn]="hide" dir="{{textDir}}">

    <div class="col s12">
        <div dir="{{textDir}}" i18n>
            <form action="#" dir="{{textDir}}">
                <div class="file-field input-field" i18n dir="{{textDir}}">
                    <div class="btn btn-submit">
                        <span class="material-icons" i18n>file_upload</span>{{'Customer.Select_File' | translate}}
                        <input type="file"  accept=".pdf , .png , .jpg" ng2FileSelect [uploader]="uploader" multiple />
                    </div>
                    <div class="file-path-wrapper" i18n>
                        <input class="file-path" type="text" placeholder="{{ 'Upload one or more files' | translate}}" >
                    </div>
                </div>
            </form>
        </div>
    </div>


    <div class="container" style="width: 100%" dir="{{textDir}}">

        <div class="col s12 file-path-wrapper">
            <h6 >{{ 'Customer.Upload_queue' | translate }} </h6>

            <table class="table">
                <thead>
                    <tr>
                        <th width="5%" i18n>{{ 'Customer.Order' | translate }} </th>
                        <th width="45%" i18n>{{ 'Customer.Name' | translate }}</th>
                        <th ng-show="" i18n>{{ 'Customer.Size' | translate }}</th>

                        <th i18n>{{ 'Customer.Actions' | translate }} </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- ngRepeat: item in uploader.queue -->
                    <tr *ngFor="let item of uploader.queue ; let i = index" [attr.data-index]="i">
                        <td>{{i +1}}</td>
                        <td width="40%"><strong>{{ item?.file?.name }}</strong></td>
                        <td ng-show="filesAmount" nowrap>{{ item?.file?.size }} KB </td>
                        <td nowrap>
                            <button type="button" class="btn-small delete" (click)="delete(i)">
                                <span class="material-icons" i18n>delete</span> {{'Customer.Delete' | translate}}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf='loading_gif' i18n> {{'Customer.Uploading_please_wait' | translate}}
                <mat-progress-bar mode="query"></mat-progress-bar>
            </div>

            <button type=" button" class="btn-small upload" (click)="upload()" i18n>
                <!--ng-disabled="!uploader.getNotUploadedItems().length" disabled="disabled">-->
                <div class="material-icons" >file_upload</div>{{'Customer.Upload' | translate}}
            </button>
            <button type="button" class="btn-small delete" (click)="deleteAll()" i18n>
                <!--ng-disabled="!uploader.queue.length" disabled="disabled">-->
                <span class="material-icons">delete</span>{{'Customer.Delete_All' | translate}}
            </button>
        </div>
    </div>
</div>
