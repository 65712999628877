import { Component, OnInit, Inject, LOCALE_ID, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {


  selectedLang = "en"

  constructor(@Inject(LOCALE_ID) protected localeId: string ,  public translate: TranslateService) { 

    translate.getDefaultLang();
    
    if ( translate.getDefaultLang() == "en"){

      this.translate.setDefaultLang('en');
      this.selectedLang = "en"

    }else if (translate.getDefaultLang() == "ar") {

      this.translate.setDefaultLang('ar');
      this.selectedLang = "ar";
     

    }

    
  }

  ngOnInit() {
  }

}
