import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { UserService } from '../shared/user.service';
import { MatSnackBar } from '@angular/material'
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { browser } from 'protractor';

@Component({
  selector: 'app-anonymous-user',
  templateUrl: './anonymous-user.component.html',
  styleUrls: ['./anonymous-user.component.css']
})


export class AnonymousUserComponent implements OnInit {

  selectedLang = "العربيه"


  
  cards = [
    { 'title': 'Emirates ID', 'subTitle': 'First Required Document' },
    { 'title': 'Utility Bill', 'subTitle': 'Second Required Document' },
    { 'title': 'Title deed or Villa Ownership document', 'subTitle': 'Third Required Document' }
  ]
  cardsEn = [
    { 'title': 'Emirates ID', 'subTitle': 'First Required Document' },
    { 'title': 'Utility Bill', 'subTitle': 'Second Required Document' },
    { 'title': 'Title deed or Villa Ownership document', 'subTitle': 'Third Required Document' }
  ]

  cardsAr = [
    { 'title': 'رقم الهويه', 'subTitle': 'اولا' },
    { 'title': 'فاتورة الكهرباء لآخر شهر', 'subTitle': 'ثانيا' },
    { 'title': 'سند الملكية', 'subTitle': 'ثالثا' }
  ]

  numberChangingMessage :string ;
  numberChangingMessageEn :string = 'Your phone number is successfully updated' ;
  numberChangingMessageAr :string = 'تم تحديث رقم الهاتف بنجاح' ;

  UpdateFailed : string ; 
  UpdateFailedEn : string = 'Update Failed' ;
  UpdateFailedAr : string = 'فشل التحديث' ;


  isError: boolean = false;
  isvalidUser: boolean = false;

  userEmiratesId;
  localID;
  userID;
  userName;
  phoneNumber;
  editPh = false;
  progressPercent: number = 63.3;
  customer = "customer";

  


  constructor(@Inject(LOCALE_ID) protected localeId: string, private userService: UserService, private snackBar: MatSnackBar, private route: ActivatedRoute, public translate: TranslateService) {

    translate.addLangs(["en", "ar"]);
    const browserLang = translate.getBrowserLang();
    this.translate.setDefaultLang('ar');
    this.cards = this.cardsAr
    this.numberChangingMessage = this.numberChangingMessageAr
    this.UpdateFailed = this.UpdateFailedAr


  }

  switchLanguage() {
    
    if (this.selectedLang == "english"){
      this.translate.setDefaultLang('en');
      this.cards = this.cardsEn
      this.numberChangingMessage = this.numberChangingMessageEn
      this.UpdateFailed = this.UpdateFailedEn 

      console.log( this.translate.getBrowserLang() + "sould be english");
     // window.location.reload();

    }else if (this.selectedLang != "english") {

      this.translate.setDefaultLang('ar');

      this.cards = this.cardsAr
      this.numberChangingMessage = this.numberChangingMessageAr
      this.UpdateFailed = this.UpdateFailedAr

      console.log( this.translate.getBrowserLang() + "should be arabic ");
     // window.location.reload();

    }
  }

  ngOnInit() {

    this.route.paramMap.subscribe(
      params => {

        let URL_ID = params.get('userId');

        this.isError = false;
        this.userService.getUserIdByUniqueUrl(URL_ID).subscribe((data: any) => {

          this.userID = data.id;
          this.userEmiratesId = data.emirates_id;
          this.userName = data.name;
          this.phoneNumber = data.phone;


        },
          (err: HttpErrorResponse) => {
            this.isError = true;
            console.log(err);
          });



      }

    );


  }
  onEditClk() {
    this.editPh = true;
  }
  updatePhone(phone) {
    console.log(phone, { 'phone': phone })

    this.userService.putUpdatePhone(this.userID, phone).subscribe((data: any) => {
    this.phoneNumber = data.phone;
      this.openSnackBar(this.numberChangingMessage);
      this.editPh = false;
    }
      , (err: HttpErrorResponse) => {
        this.openSnackBar(this.UpdateFailed)
      })


  }
  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, { duration: 2000, });
  }
  OnSubmit(EmiratesId) {


    if (this.userEmiratesId == EmiratesId) {


      this.isvalidUser = true;
      this.isError = false;



    } else {

      this.isError = true;


    }
  }

}
