<div *ngIf='dataLength == 0' class="Loading">

       <mat-spinner strokeWidth="3" [diameter]="100"></mat-spinner>

</div>


<div >

       <div class="row" dir="{{textDir}}">

              <div class="block"> <input matInput type="text" (keyup)="doFilter($event.target.value)"
                            placeholder="{{ 'Filter' | translate }}">
              </div>
              <div class="block2">
                     <div>
                            <mat-form-field style = 'font-size: 13px;'class='select-btn' >
                                   <mat-label style = ' font-size: 13px;' i18n>{{ 'Status' | translate }}</mat-label>
                                   <mat-select style = ' font-size: 13px;'  [(value)]="newStatus">
                                          <mat-option i18n>{{ 'Change Status to' | translate}}</mat-option>
                                          <div *ngFor='let status of statusChoices'>
                                                 <mat-option [value]='status' (click)="doFilter(status)">
                                                        {{status | translate}}
                                                 </mat-option>
                                          </div>
                                   </mat-select>
                            </mat-form-field>


                     </div>
              </div>

       </div>

       <mat-table [dataSource]="dataSource"  matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>

              <ng-container matColumnDef="search">
                     <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style" i18n>{{ 'Show Customer Details' | translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" >
                            <button class="btn-small"  (click)='sendEmiratesIdToParent(customer.emirates_id)'>
                                   <i class="small material-icons "    >find_in_page</i></button></mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                     <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style" i18n>{{ 'Name' | translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" >{{customer.name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="office_name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style" i18n>{{ 'Office Name' | translate }}</mat-header-cell>
                            <mat-cell *matCellDef="let customer" >{{customer.office_name}}</mat-cell>
              </ng-container>
            
              <ng-container matColumnDef="emirates_id">
                     <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="100px" class="table-header-style" i18n>{{ 'Emirates ID' | translate}}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" fxFlex="100px">{{customer.emirates_id}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="phone">
                     <mat-header-cell *matHeaderCellDef mat-sort-header  class="table-header-style" fxFlex="60px" i18n>{{ 'Phone' | translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" fxFlex="60px">{{customer.phone}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="status">
                     <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style" i18n>{{ 'Status' |  translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" >{{ customer.status | translate}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="updated_at">
                     <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style"  i18n>{{ 'Updated Date' |  translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" >{{customer.updated_at}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="support_number">
                     <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style" i18n>{{ 'Case ID' | translate }}</mat-header-cell>
                     <mat-cell *matCellDef="let customer" >{{customer.support_number}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="order_status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header-style"  i18n>{{ 'Order Status' | translate }}</mat-header-cell>
                          <!--  <mat-cell *matCellDef="let customer" [ngClass]="{'make-gold': (customer.order_status =='Ceased' || customer.order_status == 'موقوفة')
                           || customer.order_status == 'المساعدة موقوفة'  || customer.order_status == 'Stopped case') } ">
                    {{customer.order_status | translate}}
               </mat-cell> -->
                               <mat-cell *matCellDef="let customer" [ngClass]="{'make-gold' : (customer.order_status == 'Ceased')}">
                                        {{customer.order_status | translate }}
                                </mat-cell>


              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
              <mat-row *matRowDef="let row; columns:displayedColumns"></mat-row>
       </mat-table>

       <mat-paginator [pageSize]="10" [pageSizeOptions]="[2, 4, 6, 10, 20]">
       </mat-paginator>


       <button class="btn btn-submit  right"
              [disabled]="newStatus == undefined || newStatus == 'pending approval' || newStatus ==  'No File Uploaded' || newStatus == 'new files pending approval'"
              type="submit" (click)="downloadFile(newStatus)">{{ 'Export' | translate}}</button>


</div>
