import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent {


  @Input()customerComments:[]  ;
  textDir: string;

  constructor(public translate: TranslateService) { 
    translate.addLangs(["en", "ar"]);
    const browserLang =  this.translate.getDefaultLang();
    console.log("Browser Language  comments", browserLang); 
    if(browserLang == 'ar'){
        this.textDir = 'rtl';
    }
    else{
      this.textDir = 'ltr';
    }  
  }



}
