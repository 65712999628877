/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidenavbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i3 from "@angular/material/sidenav";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/router";
import * as i8 from "./sidenavbar.component";
var styles_SidenavbarComponent = [i0.styles];
var RenderType_SidenavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidenavbarComponent, data: {} });
export { RenderType_SidenavbarComponent as RenderType_SidenavbarComponent };
export function View_SidenavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-sidenav-container", [["class", "mat-drawer-container mat-sidenav-container"]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i2.View_MatSidenavContainer_0, i2.RenderType_MatSidenavContainer)), i1.ɵdid(1, 1490944, null, 2, i3.MatSidenavContainer, [[2, i4.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i3.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i5.ANIMATION_MODULE_TYPE], [2, i6.ViewportRuler]], null, null), i1.ɵqud(603979776, 1, { _drawers: 1 }), i1.ɵqud(335544320, 2, { _content: 0 }), (_l()(), i1.ɵeld(4, 0, null, 1, 5, "mat-sidenav-content", [["class", "mat-drawer-content mat-sidenav-content"]], [[4, "margin-left", "px"], [4, "margin-right", "px"]], null, null, i2.View_MatSidenavContent_0, i2.RenderType_MatSidenavContent)), i1.ɵdid(5, 1294336, [[2, 4]], 0, i3.MatSidenavContent, [i1.ChangeDetectorRef, i3.MatSidenavContainer, i1.ElementRef, i6.ScrollDispatcher, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 16777216, null, 0, 1, "router-outlet", [["name", "popup"]], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "popup"], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._backdropOverride; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5)._container._contentMargins.left; var currVal_2 = i1.ɵnov(_v, 5)._container._contentMargins.right; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_SidenavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidenavbar", [], null, null, null, View_SidenavbarComponent_0, RenderType_SidenavbarComponent)), i1.ɵdid(1, 114688, null, 0, i8.SidenavbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidenavbarComponentNgFactory = i1.ɵccf("sidenavbar", i8.SidenavbarComponent, View_SidenavbarComponent_Host_0, {}, {}, []);
export { SidenavbarComponentNgFactory as SidenavbarComponentNgFactory };
