import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {
	codedUserGroup=localStorage.getItem(btoa("userGroup"));
	userGroup=atob(this.codedUserGroup);

  constructor() { 




  }

  ngOnInit() {
  
  }

}