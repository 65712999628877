import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common'

import { Observable } from 'rxjs/Observable';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(private router: Router, private location: Location) { }
  canActivate(

    route, //:ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let codedUserToken = localStorage.getItem(btoa('userToken'))
    let userToken = atob(codedUserToken)

    if (codedUserToken != 'ée') {

      return true;
    }
    else {
      localStorage.removeItem(btoa('userToken'))
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    }
    //this.location.replaceState('/login')

    return false;
  }
}
