import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UploadService = /** @class */ (function () {
    function UploadService(http) {
        this.http = http;
        this.anonymous_user_static_token = environment.anonymous_user_static_token;
        this.headers = {};
        this.DJANGO_SERVER = environment.rootUrl;
    }
    UploadService.prototype.upload = function (formData, userID) {
        var codedUserToken = localStorage.getItem(btoa('userToken'));
        if (codedUserToken == 'ée' || !codedUserToken) {
            this.headers = this.anonymous_user_static_token;
        }
        return this.http.post(this.DJANGO_SERVER + "/file/upload/" + userID + '/', formData, { headers: this.headers });
    };
    UploadService.ngInjectableDef = i0.defineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.inject(i1.HttpClient)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
