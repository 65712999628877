

<div  style="margin-right: 20%;margin-left: 20%;box-shadow: 2px 10px 8px 10px #888888;" *ngIf="!passwordChangeSuccess">
    <p *ngIf="!passwordChangeSuccess" class="textHeading2" dir="rtl" style="position: relative;margin-right:22%;margin-left: 10%;margin-top: 3% !important;font-weight: bold;color:#bca138;padding-top: 5%">{{ 'Please Answer The Below Security Questions To Proceed' | translate }}</p>
<form  [formGroup]="securityForm" *ngIf="!securityVerified && !passwordChangeSuccess" autocomplete="off"   fxLayout="column wrap"
fxLayoutAlign="center center" fxLayoutGap="10px"  #editForm="ngForm" id="editForm" (ngSubmit)="createSecurityAnswers(securityForm.value)">

<mat-card-content class="mat-card-content" dir="{{textDir}}" style="width:100%;height: 100%;margin-bottom: 2%;overflow:unset !important">

<div class="row" dir="{{textDir}}">
    <mat-form-field dir="{{textDir}}" class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
            <input matInput type="text" class="login_text login_align"  formControlName="UserName" required>
            <label  i18n  class="login_align_label">{{ 'User Name' | translate }}</label>
            </mat-form-field>
    </div>

<div class="row">
        <mat-form-field class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
                <input matInput (input)="onSearchChangeNewPassword($event.target.value)" [type]="hide ? 'password' : 'text'" class="login_text login_align"  formControlName="NewPassword" required>
               
                <mat-icon class="eye_pos" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <label  i18n class="login_align_label">{{ 'New Password' | translate }}</label>
        </mat-form-field>
</div>


<div class="row">
    <mat-form-field class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
            <input matInput (input)="onSearchChange($event.target.value)" [type]="hidenew ? 'password' : 'text'" class="login_text login_align"  formControlName="NewPassword2" required>
            <mat-icon class="eye_pos" matSuffix (click)="hidenew = !hidenew">{{hidenew ? 'visibility_off' : 'visibility'}}</mat-icon>
              
            <label  i18n class="login_align_label">{{ 'Re-Enter New Password' | translate }}</label>
            <mat-hint *ngIf="passwordError" style="color:red;font-size: 15px">{{passwordErrorMsg | translate}}</mat-hint>
            </mat-form-field>
</div>

<div class="row">
        <mat-form-field class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
        <input matInput  type="text" class="login_text login_align"  formControlName="AnswerOne"   name="AnswerOne" required >
        <label  i18n class="login_align_label">{{questionOne | translate}}</label>
        </mat-form-field>
</div>      


<div class="row">
        <mat-form-field class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
        <input matInput type="text" class="login_text login_align"   formControlName="AnswerTwo" required>
        <label  i18n class="login_align_label">{{questionTwo | translate}}</label>
        </mat-form-field>
</div>

<div class="row">
<mat-form-field class="row_align input-field col s8 offset-s3" style="position:relative;margin-left:10%" appearance="none">
        <input matInput type="text" class="login_text login_align"  formControlName="AnswerThree" required>
        <label  i18n class="login_align_label">{{questionThree | translate}}</label>
       </mat-form-field>
</div>



</mat-card-content>
<mat-card-actions>
<mat-card-actions align="center">
  <button mat-raised-button color="primary" [disabled]="!securityForm.valid || passwordError" (click)="createSecurityAnswers(securityForm.value)">{{ 'Create' | translate }}</button>
  <button type="button" mat-raised-button color="warn" (click)="Cancel()">{{ 'Cancel' | translate }}</button>
</mat-card-actions>
</mat-card-actions>
</form>

</div>
<img src="assets/correctImage.png" *ngIf="passwordChangeSuccess && !passwordError && !passwordErrorFinal" alt="Done" height="400" width="400" style="margin-left:35%;margin-right:10%;margin-top: 5%" >
<img src="assets/img/cross.png" *ngIf="passwordErrorFinal" alt="Done" height="250" width="250" style="margin-left:35%;margin-right:10%;margin-top: 5%;margin-bottom: 2%" >

<p  *ngIf="passwordChangeSuccess && !passwordErrorFinal" class="textHeading2" style="margin-left:39%;margin-right:10%" >{{ 'Password Changed Successfully' | translate }}</p>

<p  *ngIf="passwordErrorFinal" class="textHeading2" style="margin-left:40%;margin-right:10%" >{{ 'Password Could Not Be Changed Successfully' | translate }}</p>

