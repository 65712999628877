<h4 *ngIf='customerComments?.length==0' class="isa_warning">No comments on this customer</h4>


<div  class="scroll">


<div id="myDev" class="row" *ngFor="let comment of customerComments" >

    

  <div >

      <mat-card>
   
    <ul i18n dir="{{textDir}}">
      <li i18n >{{ 'User' | translate }} :{{comment.user | translate}}</li>
      <li *ngIf='comment.comment.length > 0  ' i18n >{{ 'Comment' | translate }}: {{comment.comment | translate}}</li>
      <li i18n>{{ 'Status' | translate }} : {{comment.status | translate}}</li>
      <li *ngIf='comment.reject_reason.length > 0' i18n>{{ 'Rejection reason' | translate }}: {{comment.reject_reason | translate}}</li>
      <li i18n>{{ 'Updated at' | translate }}: {{comment.updated_at | translate}}</li>
    </ul>
    
  </mat-card>
  <br>
  </div>

</div>
</div>