<div class="scroll">



	<table>
		<thead>
			<th>{{ 'File Name' | translate }}</th>
			<th>{{ 'Show File' | translate }}</th>
		</thead>
		<tr *ngFor="let file of imageList">
			<td>{{ file }}</td>

			<td>
				<div>
					<button (click)=showImage(file) class='showButton'>{{ 'Show' | translate }}</button>
				</div>
			</td>
	</table>
	<div *ngIf='displayModel' class="Modal" [style.display]="displayModel ? 'block':'none'">
		<!-- Modal content -->
		<div class="modal-content" (clickOutsideMe)=onClick($event)>
			<span class="close" (click)=onClick($event)>&times;</span>
			<div>

				<ngx-image-viewer class="responsive-img" [src]="[imageUrl]"></ngx-image-viewer>
			</div>
		</div>

	</div>

</div>

<!---->