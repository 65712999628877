import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from './../../environments/environment'
@Component({
  selector: 'app-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.css']
})
export class ShowPdfComponent implements OnInit {
  fileName;
  rootUrl = environment.rootUrl + '/media/';
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {

        this.fileName = this.rootUrl + params.get('fileName')

      });
  }

}
