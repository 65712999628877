
export  class  AppUserAuth {

    userName: string = "";
    Token: string = "";
    userGroup: string = "";
    isAuthenticated: boolean = false;
    canReject: boolean = false;
    canUploadFiles: boolean = false;
    canClose: boolean = false;
    
  }