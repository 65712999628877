<div class="navbar-fixed" style="height:90px;">
  <nav class="nav-wrapper-first" style="height:90px;">
    <div>
      <a class="brand-logo"><img src="assets/img/ministry-of-interial.png" class="sidenav-icon left-logo"></a>
      <ul class="right hide-on-med-and-down">

        <img src="assets/img/hussantuk.png" class="right hide-on-med-and-down sidenav-icon right-logo">
      </ul>

      <ul class="right hide-on-med-and-down ">

        <a><img src="assets/img/etisalat_logo.png" class="etisalat-logo"> </a>
      </ul>
    </div>
  </nav>
</div>
