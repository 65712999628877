<div class="navbar-fixed" style="height:90px;">
  <nav class="nav-wrapper-first" style="height:90px;">
    <div>
      <a class="brand-logo"><img src="assets/img/ministry-of-interial.png" class="sidenav-icon left-logo"></a>
      <ul class="right hide-on-med-and-down">

        <img src="assets/img/hussantuk.png" class="right hide-on-med-and-down sidenav-icon right-logo">
      </ul>
      <ul class="right hide-on-med-and-down">

        <a><img src="assets/img/etisalat_logo.png" class="sidenav-icon etisalat-logo"> </a>
      </ul>
    </div>
  </nav>
</div>

<nav>
  <nav>
    <div class="nav-wrapper">
      <img class="responsive-img" src="assets/img/EtisalatTopBandMedia.png">
      
      <a href="#" class="brand-logo center" i18n>{{ 'Customer Data Management' | translate }}</a>
      
      <button class="logoutbutton"><img class="logoutlogo" src="assets/img/inside-logout-icon.png"
          (click)="Logout()"></button>
      <!--<a href="/home/changepassword" class="changePasswordbutton" i18n><img src="/assets/img/changepassword.png" width=40 height=40></a>-->
      
         <ul class="text_font_select" > 
          <mat-form-field class="lang_select" style="width:200px !important">
              <mat-label  style="width:250px !important;color: white">{{ 'Select Your Language' | translate}}</mat-label>
              <mat-select style="align-items:left" [(value)]="selectedLang" name="action" 
              (selectionChange)="switchLanguage()">
          
                <mat-option value="english" class="text_font_option" >English</mat-option>
                <mat-option value="العربيه" class="text_font_option">العربيه</mat-option>
              </mat-select>
            </mat-form-field>
        </ul>

      <a href="/admin/upload" *ngIf="userGroup == 'upload'" class="changePasswordbutton" ><img src="/assets/img/uploadSmall.png" style="background-color:white" width=30 height=30></a>
     

    </div>
  </nav>