import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, ResponseType } from "@angular/http";
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { User } from './user.model';
import { customer } from './customer.model';
import { AppUserAuth } from '../auth/app-user-auth';
import { environment } from './../../environments/environment'
import { text } from '@angular/core/src/render3';

@Injectable()
export class UserService {
  readonly authHeader = new HttpHeaders({ 'No-Auth': 'True' });
  readonly rootUrl = environment.rootUrl;
  readonly anonymous_user_static_token = environment.anonymous_user_static_token
  securityObject: AppUserAuth;

  constructor(private http: HttpClient) {


    this.securityObject = new AppUserAuth();


  }

  registerUser(user: User) {

    const body: User = {
      UserName: user.UserName,
      Password: user.Password,
      Email: user.Email,
      FirstName: user.FirstName,
      LastName: user.LastName

    }

    return this.http.post(this.rootUrl + '/api/User/Register', body)//{headers : reqHeader});
  }

  changePassword(secAnswerVerifyObj){

    const formData = new FormData();
    formData.append('username', secAnswerVerifyObj.UserName);
    formData.append('new_password', secAnswerVerifyObj.NewPassword);
    formData.append('question_1', '1');
    formData.append('question_2', '2');
    formData.append('question_3', '3');
    formData.append('answer_1', secAnswerVerifyObj.AnswerOne);
    formData.append('answer_2', secAnswerVerifyObj.AnswerTwo); 
    formData.append('answer_3', secAnswerVerifyObj.AnswerThree);
   
      const url = this.rootUrl  + "/security/questions/changepassword";
      console.log("I am adding answers to security questions");
      return this.http
        .post<any>(url, formData, {headers: this.authHeader})

  }

getSecurityAnswers(){
  const url = this.rootUrl  + "/security/questions/set";
  return this.http.get(url );
}

addSecurityAnswers(id,answer){
    const formData = new FormData();
    formData.append('question_id', id);
    formData.append('answer', answer);
   
      const url = this.rootUrl  + "/security/questions/set";
      console.log("I am adding answers to security questions")
      return this.http
        .post<any>(url, formData)
  
  }


  getSecurityQuestions(){
   
    return this.http.get(this.rootUrl + "/security/questions/list/all" , { headers: this.anonymous_user_static_token });
  }

  userAuthentication(credentials) {
    //console.log(typeof(credentials))
    //var data=credentials

    return this.http.post(this.rootUrl + '/api-token/api-token-auth/', credentials, { headers: this.authHeader });

  }

  getUserIdByUniqueUrl(ID) {


    return this.http.get(this.rootUrl + "/customer/customer_details/is_exist/" + ID+"/", { headers: this.anonymous_user_static_token });
  }
  

  getNewExportData(status){
    
    return this.http.get(this.rootUrl + "/update_case/downloads/" + status, { responseType: 'text'});

  }

  getExportedData(status) {


     window.open(this.rootUrl + "/export/?status=" + status, +"/" + '_self');



  }


  getCustomerDetailes(customerID) {


    return this.http.get(this.rootUrl + "/customer/customer_details/" + customerID + "/");

  }


  getCommentsOnCustomer(customerID) {

    return this.http.get(this.rootUrl + "/case-state/comments/" + customerID );

  }


  getCustomerFile(customerID) {


    return this.http.get(this.rootUrl + "/file/upload/" + customerID + "/");


  }

  getCustomerTable(): Observable<customer[]> {

    //return this.http.get<customer[]>(this.rootUrl + "/customer/get/all?page=1");
    return this.http.get<customer[]>(this.rootUrl + "/customer/get/all/WithStatus");

  }

  putChangeStatus(customerId, statusData) {

    return this.http.post(this.rootUrl + '/case-state/status/' + customerId, statusData)

  }

  putUpdatePhone(customerId, data) {

    return this.http.put(this.rootUrl + '/customer/' + customerId+'/phone', data,{ headers: this.anonymous_user_static_token })

  }
}
