import { Component, OnInit,OnDestroy,ChangeDetectorRef , Output, EventEmitter,HostListener, ViewChild, Inject, LOCALE_ID } from '@angular/core';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material'
import { CustomersTableComponent } from '../customers-table/customers-table.component'
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { FormGroup, FormControl, Validators } from '@angular/forms';



export interface DialogData {
 
}

export interface SecurityCreation {
 
  
  AnswerOne : String;
  AnswerTwo : String;
  AnswerThree : String;
  
  
}


@Component({
  selector: 'app-moi-customers',
  templateUrl: './moi-customers.component.html',
  styleUrls: ['./moi-customers.component.css'],
  providers: [{provide: MAT_DIALOG_DATA, useValue: {}} ]
})
export class MoiCustomersComponent implements OnInit,OnDestroy {

  isError: boolean = false;
  files = [];
  customerDetails = [];
  isShowStatus = false;
  isShowFiles = false;
  imageURL = 'None';
  rootUrl = 'http://10.0.147.69:8090/media/';
  searchPlaceHolder = 'Search with emirates ID';
  customerComments: [];
  userGroup ;
  selectedLang = "العربيه";

  @Output() outputToCustomerTable = new EventEmitter<object>();
  dialogRef: any;
  dialogueOpened: boolean = true;
  selectedIndex: number;
  excelList: any;
  exportList: Observable<Object>;
  textDir: string;

  constructor(private cdr: ChangeDetectorRef,@Inject(LOCALE_ID) protected localeId: string,public translate: TranslateService,private userService: UserService, private toastr: ToastrService, private snackBar: MatSnackBar,public dialog: MatDialog ,public overlay: Overlay,
  @Inject(MAT_DIALOG_DATA) public data: {}) {
    
      translate.addLangs(["en", "ar"]);
      const browserLang = this.translate.getDefaultLang();
      console.log("Browser Language moi customer", browserLang);
      if(browserLang == 'ar'){
          this.textDir = 'rtl';
      }
      else{
        this.textDir = 'ltr';
      }
      
   }
  @ViewChild(CustomersTableComponent)
  CustomersTableComponentInstance: CustomersTableComponent;

  @HostListener('window:beforeunload')
  ngOnDestroy(){
    console.log('destroyed')
    //localStorage.removeItem(btoa("userGroup"))
    //localStorage.removeItem(btoa("userToken"))
    console.log('destroyed')
    




  
  }
 
 
  ngOnInit() {
    this.showStatus();
    
  }

  ngAfterViewChecked(){
    //your code to update the model
    const browserLang = this.translate.getDefaultLang();
    console.log("Browser Language moi customer", browserLang);
    if(browserLang == 'ar'){
        this.textDir = 'rtl';
    }
    else{
      this.textDir = 'ltr';
    }
    this.cdr.detectChanges();

 }

  openDialogSecurity(): void {
   
    if((this.dialogRef != null) ){
        
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(result => {
             
             
            });
            this.dialogRef = null;
    }
    else(this.dialogRef == null) 
    {
          console.log("Disabled Value " + this.dialogueOpened);
          this.dialogRef = this.dialog.open(DialogOverviewExampleDialogActionsSecurity, {
            height:"92%",
            width:"50%",
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            autoFocus: false,
            data: {},
            disableClose: true
          });

          this.dialogRef.afterClosed().subscribe(
            result => {console.log("Dialog Comment:", result);
            //console.log("Dialog output:", result.data)
        }
        ); 
    }
  }
  


  showStatus(){
    let codedUserGroup = localStorage.getItem(btoa("userGroup"))
    let userGroup = atob(codedUserGroup) 
    

    if (userGroup == 'non_admin'){
      this.isShowStatus =  false;
      this.selectedIndex = 1;
    }
    else if(userGroup == 'documentview'){
      this.isShowStatus =  false;
      this.isShowFiles =  false;
      this.selectedIndex = 1;
    }
    else{
      this.isShowStatus =  true;
      this.isShowFiles =  true;
      this.selectedIndex = 0;
    }
    
    console.log("Admin", this.isShowStatus)
   
    this.userService.getSecurityAnswers().subscribe((data: any) => {

      console.log(data)
      if(data.length == 3){
        console.log("Already answered security question");
      }
      else{
        //setTimeout(() => this.openDialogSecurity());
       this.openDialogSecurity();
      }



    },
      (err: HttpErrorResponse) => {
        
        this.isError = true;
        console.log(err);
      });
    

   
  }

  OnSubmit(CustomrID) {
    this.isError = false;
    this.isShowStatus =  true;
    this.isShowFiles =  true;
    this.selectedIndex = 0;
    this.userService.getCustomerDetailes(CustomrID).subscribe((data: any) => {

      console.log(data);
      console.log("this.selectedIndex ", this.selectedIndex);
      if(data["order_status"] == 'Ceased' || data["order_status"] == 'ceased' || data["order_status"] ==  "موقوفة"
      || data["order_status"] == 'المساعدة موقوفة' || data["order_status"] == 'Stopped case' ){
        this.isShowStatus =  false;
        this.selectedIndex = 1;
       
      }
      this.files = [];
      if(this.selectedIndex == 1){
        this.getFiles(CustomrID);
      }
      this.customerDetails = [];
      this.customerDetails.push(data);
      



    },
      (err: HttpErrorResponse) => {
        debugger
        this.isError = true;
        console.log(err);
      });

  }

  getEmiratesIDFromSearchTable(customerEmiratesID: string) {

    if (customerEmiratesID) {
      this.searchPlaceHolder = customerEmiratesID
      this.OnSubmit(customerEmiratesID)



    }

  }
  //check if the clicked tab is show files it will call the files. 
  tabChanged($event, customerId) {
    console.log("Tab changes event ", $event.tab.textLabel);
   
    if ($event.tab.textLabel == 'Show Files' || $event.tab.textLabel == 'اظهار الملفات' ) {

      this.getFiles(customerId)

    }
    else if ($event.tab.textLabel == 'Comments' || $event.tab.textLabel == "ملاحظات") {
      this.getAllComments(customerId)

    }
  }


  
  getFiles(customerID) {

    this.files = [];
    this.userService.getCustomerFile(customerID).subscribe((data: any) => {

      //this.files = data;
      data.forEach(file => {
      console.log(Object.values(this.files));
      if (Object.values(this.files).length == 0 ){
          this.files.push(decodeURIComponent(file.file));
        }
    else {
            if(!(decodeURIComponent(file.file) in this.files))
              console.log(decodeURIComponent(file.file));
              {
                this.files.push(decodeURIComponent(file.file));
              }
          }
      });


    },
      (err: HttpErrorResponse) => {
        this.isError = true;
        console.log(err);
      });

  }
  //showFile(fileURL){
  //     //window.open("http://127.0.0.1:8090"+fileURL, "_blank");
  //     //console.log(this.imageURL)
  //     this.imageURL=(this.rootUrl+fileURL)
  //
  //     console.log(this.imageURL.toString() )

  //}
  getAllComments(CustomrID) {
    //

    this.userService.getCommentsOnCustomer(CustomrID).subscribe((data: any) => {


      this.customerComments = data;




    },
      (err: HttpErrorResponse) => {

        console.log(err);
      });

  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, { duration: 4000, });
  }
  updateStatus(changedStatusData) {
    this.CustomersTableComponentInstance.dataSource.filter = changedStatusData.emirates_id
    this.CustomersTableComponentInstance.dataSource.filteredData[0].status = changedStatusData.status

    //console.log(this.dataSource.filteredData[0].name='sssssssssssssss')
    this.CustomersTableComponentInstance.dataSource.filter = ''
  };

  

}

@Component({
  selector: 'app-dialog-overview-example-dialog-actions-security-cit',
  templateUrl: 'user-security-popup.html',
  styleUrls: ['./moi-customers.component.css']
})
export class DialogOverviewExampleDialogActionsSecurity {
  securityQrequest: any;
  questionOne : String;
  questionTwo : String;
  questionThree : String;
  ownerForm: any;
  securityForm: any;
  selectedLang = "العربيه";
  textDir: string = 'ltr';
  constructor(private cdr: ChangeDetectorRef,public translate: TranslateService,public dialogRef: MatDialogRef<DialogOverviewExampleDialogActionsSecurity>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,  private http: HttpClient,private userService: UserService) {
      this.processSecurityQuestions(); 
      
      const browserLang = translate.getDefaultLang();
      if(browserLang == 'ar'){
        this.textDir = 'rtl';
      }
      else{
        this.textDir = 'ltr';
      }
      
    }

    ngAfterViewChecked(){
      //your code to update the model
      const browserLang = this.translate.getDefaultLang();
      console.log("Browser Language moi customer", browserLang);
      if(browserLang == 'ar'){
          this.textDir = 'rtl';
      }
      else{
        this.textDir = 'ltr';
      }
      this.cdr.detectChanges();
  
   }


    ngOnInit() {
      
      this.securityForm = new FormGroup({
       
        AnswerOne: new FormControl('', [Validators.required, Validators.maxLength(300)]),
        AnswerTwo: new FormControl('', [Validators.required, Validators.maxLength(300)]),
        AnswerThree: new FormControl('', [Validators.required, Validators.maxLength(300)]),
       
       
      });
    }

    public hasError = (controlName: string, errorName: string) =>{
      return this.securityForm.controls[controlName].hasError(errorName);
    }

    public createSecurityAnswers = (ownerFormValue) => {
      if (this.securityForm.valid) {
        console.log("Owner Form Value ", ownerFormValue);
        this.executeOwnerCreation(ownerFormValue);
      }
    }
  
    private executeOwnerCreation(securityAnswersObj){
    
       var securityQsObj: SecurityCreation = {
        AnswerOne: securityAnswersObj.AnswerOne,
        //dateOfBirth: ownerFormValue.dateOfBirth,
        AnswerTwo : securityAnswersObj.AnswerTwo,
        AnswerThree: securityAnswersObj.AnswerThree
      
      }
      console.log("answer 1 = ",securityQsObj.AnswerOne );
      console.log("answer 2 = ",securityQsObj.AnswerTwo );
      console.log("answer 3 = ",securityQsObj.AnswerThree );
      this.dialogRef.close({ event: 'close' , data:{}});
  
      var securityAnswerrequest = this.userService.addSecurityAnswers(1, securityQsObj.AnswerOne);
      securityAnswerrequest.subscribe(
        (response: any) => {
          console.log("Response form Security Answer Set API",response );
      },
      errorMessage => {
        console.log("Error found", errorMessage)
      }
    )

    var securityAnswerrequest = this.userService.addSecurityAnswers(2, securityQsObj.AnswerTwo);
    securityAnswerrequest.subscribe(
      (response: any) => {
        console.log("Response form Security Answer Set API",response );
    },
    errorMessage => {
      console.log("Error found", errorMessage)
    }
  )


  var securityAnswerrequest = this.userService.addSecurityAnswers(3, securityQsObj.AnswerThree);
  securityAnswerrequest.subscribe(
    (response: any) => {
      console.log("Response form Security Answer Set API",response );
  },
  errorMessage => {
    console.log("Error found", errorMessage)
  }
)


    
  
    }

processSecurityQuestions(){
this.securityQrequest = this.userService.getSecurityQuestions();
this.securityQrequest.subscribe(
  (response: any) => {
    console.log("Printing test Response = ", response);
    this.questionOne = response[0]["question"];
    this.questionTwo = response[1]["question"];
    this.questionThree = response[2]["question"];
    console.log(" questionOne = ", this.questionOne);
    console.log(" questionTwo = ", this.questionTwo);
    console.log(" questionThree = ", this.questionThree);

  
   
    
},
errorMessage => {
  console.log("Error found", errorMessage);
}
);



}


    Cancel(){
      this.dialogRef.close({ event: 'close' , data:{}});
    }



    OnClickSchedule(){
    console.log("IN confirm of test review");
    this.dialogRef.close({ event: 'close' , data:{}});
  }



}
