import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'
@Injectable({
  providedIn: 'root'
})

export class UploadService {
  readonly anonymous_user_static_token = environment.anonymous_user_static_token
  headers = {};
  DJANGO_SERVER: string = environment.rootUrl;
  constructor(private http: HttpClient) { }

  public upload(formData, userID) {
    let codedUserToken = localStorage.getItem(btoa('userToken'))


    if (codedUserToken == 'ée' || !codedUserToken) {
      this.headers = this.anonymous_user_static_token;
    }
    return this.http.post<any>(`${this.DJANGO_SERVER}/file/upload/` + userID + '/', formData, { headers: this.headers });
  }
}