export const AdminStatusChoices=
['approved','rejected','Rejected - Documents required','Service Installed']

export const EtisalatStatusChoices=
['Service Installed', 'Installation in Progress']

export const MoiStatusChoices=
['approved','rejected','Rejected - Documents required']

export const FilterStatusChoices=
['No File Uploaded','pending approval','approved','rejected','Rejected - Documents required','Service Installed']

export const FilterStatusChoicesEti=
['No File Uploaded','pending approval','new files pending approval','approved',
'rejected','Rejected – Additional Documents Required','Accepted for installation','Service Installed', 'Installation in Progress']
