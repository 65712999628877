import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AppUserAuth } from '../auth/app-user-auth';
import { trigger, transition, style, animate } from '@angular/animations';
import { fade } from '../animation/animation';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from './../../environments/environment';


export interface SecurityCreation {
 
  
  AnswerOne : String;
  AnswerTwo : String;
  AnswerThree : String;
  UserName : String;
  NewPassword: String;
  NewPassword2 : String;
}

// Password strengths
export const enum PasswordCheckStrength {
  Short,
  Common,
  Weak,
  Ok,
  Strong,
};


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  
  readonly rootUrl = environment.rootUrl;
  securityVerified : Boolean = false;
  tryingToLoginCount = 0

 

  languageList = [

    { code: 'en', label: 'English' },
    { code: 'ar', label: 'عربى' },

  ];
  securityQrequest: any;
  questionOne: any;
  questionTwo: any;
  questionThree: any;
  securityForm: FormGroup;
  dialogRef: any;
  passwordForm: FormGroup;
  passwordChangeSuccess : boolean = false;
  passwordError : boolean = false;
  textDir: string;
  hide = true;
  hidenew = true;
  newPassword: any;
  reEneteredPassword: any;
  passwordErrorFinal: boolean = false;
  passwordErrorMsg: string;

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute, @Inject(LOCALE_ID) protected localeId: string ,public translate: TranslateService) {

    translate.addLangs(["en", "ar"]);
    this.translate.setDefaultLang('ar');
    console.log("Browser Language  change password"); 
    this.textDir = 'rtl';

   }

  ngOnInit() {
    this.securityForm = new FormGroup({
       
      AnswerOne: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      AnswerTwo: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      AnswerThree: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      UserName: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      NewPassword : new FormControl('', [Validators.required, Validators.maxLength(300)]),
      NewPassword2 : new FormControl('', [Validators.required, Validators.maxLength(300)]),
     
    });

    this.passwordForm = new FormGroup({
       
      Password1: new FormControl('', [Validators.required, Validators.maxLength(300)]),
      Password2: new FormControl('', [Validators.required, Validators.maxLength(300)]),
     
    });
    this.processSecurityQuestions();

  }

      // Expected length of all passwords
  public  get MinimumLength(): number {
        return 5;
    }

    // Regex to check for a common password string - all based on 5+ length passwords
  private commonPasswordPatterns = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;

    //
    // Checks if the given password matches a set of common password
    //
  public isPasswordCommon(password: string): boolean {
        return this.commonPasswordPatterns.test(password);
    }


  public checkPasswordStrength(password: string): PasswordCheckStrength {

    // Build up the strenth of our password
    let numberOfElements = 0;
    numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements;    
    console.log(" checkPasswordStrength  Small Leeter =  ", numberOfElements);
    numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements;      // Uppercase letters
    console.log(" checkPasswordStrength  Capital Letters=  ", numberOfElements);
    numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements;      // Numbers
    console.log(" checkPasswordStrength  Numeric  Letters=  ", numberOfElements);
    numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements;   // Special characters (inc. space)
    console.log(" checkPasswordStrength  Special  Letters=  ", numberOfElements);

    // Assume we have a poor password already
    let currentPasswordStrength = PasswordCheckStrength.Short;

    // Check then strenth of this password using some simple rules
    if (password === null || password.length < this.MinimumLength) {
        currentPasswordStrength = PasswordCheckStrength.Short;
    } else if (this.isPasswordCommon(password) === true) {
        currentPasswordStrength = PasswordCheckStrength.Common;
    } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
        currentPasswordStrength = PasswordCheckStrength.Weak;
    } else if (numberOfElements === 3) {
        currentPasswordStrength = PasswordCheckStrength.Ok;
    } else {
        currentPasswordStrength = PasswordCheckStrength.Strong;
    }

    // Return the strength of this password
    return currentPasswordStrength;
}


onSearchChange(event){
  
  this.newPassword = event;

  if(this.newPassword === this.reEneteredPassword){
    console.log("this.checkPasswordStrength(this.reEneteredPassword) ", this.checkPasswordStrength(this.reEneteredPassword));
    if(this.checkPasswordStrength(this.reEneteredPassword) === 3 || this.checkPasswordStrength(this.reEneteredPassword) === 4 ){
      console.log("Password matched so error will be false");
      this.passwordError = false;
    }
    else{
      this.passwordError = true;
      this.passwordErrorMsg = "Special character or number or character missing";
      console.log(this.reEneteredPassword);
      console.log("this.checkPasswordStrength(event) ", this.checkPasswordStrength(this.reEneteredPassword));
    }
  }
  else{
    this.passwordErrorMsg = "Password does not match";
    this.passwordError = true;
  }
}

onSearchChangeNewPassword(event){
  this.reEneteredPassword = event;


}



changePasswordFunc(event){
    console.log("Change Password function");
  }
  



public createSecurityAnswers = (ownerFormValue) => {
    if (this.securityForm.valid) {
      console.log("Owner Form Value ", ownerFormValue);
      this.executeOwnerCreation(ownerFormValue);
    }
  }


Cancel(){
  console.log("Cancelled change password");
  this.router.navigate(['/']);
}

  private executeOwnerCreation(securityAnswersObj){
  
     var securityQsObj: SecurityCreation = {
      AnswerOne: securityAnswersObj.AnswerOne,
      AnswerTwo : securityAnswersObj.AnswerTwo,
      AnswerThree: securityAnswersObj.AnswerThree,
      UserName: securityAnswersObj.UserName,
      NewPassword : securityAnswersObj.NewPassword,
      NewPassword2 :  securityAnswersObj.NewPassword2
    
    }

    console.log("answer 1 = ",securityQsObj.AnswerOne );
    console.log("answer 2 = ",securityQsObj.AnswerTwo );
    console.log("answer 3 = ",securityQsObj.AnswerThree );
    if(securityQsObj.NewPassword !=  securityQsObj.NewPassword2){
      this.passwordErrorFinal = true;
      this.passwordChangeSuccess = true;
      this.securityVerified = false;
    }

    var securityAnswerrequest = this.userService.changePassword(securityQsObj);
    securityAnswerrequest.subscribe(
      (response: any) => {
        console.log("Response form Security Answer verify API",response );
        if("message" in response){
          console.log("The password reset was sucess");
          this.passwordChangeSuccess = true;
          this.securityVerified = true;
        }
        else{
          if("error" in response){
            console.log("The password reset was an error");
            this.passwordErrorFinal = true;
            this.passwordChangeSuccess = true;
            this.securityVerified = false;
          }
        }
       
    },
    errorMessage => {
      console.log("Error found", errorMessage);
      this.passwordErrorFinal = true;
      this.passwordChangeSuccess = true;
      this.securityVerified = false;
    }
  )



  }

  processSecurityQuestions(){
    this.securityQrequest = this.userService.getSecurityQuestions();
    this.securityQrequest.subscribe(
      (response: any) => {
        console.log("Printing test Response = ", response);
        this.questionOne = response[0]["question"];
        this.questionTwo = response[1]["question"];
        this.questionThree = response[2]["question"];
        console.log(" questionOne = ", this.questionOne);
        console.log(" questionTwo = ", this.questionTwo);
        console.log(" questionThree = ", this.questionThree);
    
      
       
        
    },
    errorMessage => {
      console.log("Error found", errorMessage);
      this.passwordError= true;
    }
    );
    
    
    
    }
    
  


}
