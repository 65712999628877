/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-image.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../click-outside-me.directive";
import * as i4 from "../../../node_modules/ngx-image-viewer/ngx-image-viewer.ngfactory";
import * as i5 from "ngx-image-viewer";
import * as i6 from "@angular/common";
import * as i7 from "./show-image.component";
import * as i8 from "@angular/router";
var styles_ShowImageComponent = [i0.styles];
var RenderType_ShowImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowImageComponent, data: {} });
export { RenderType_ShowImageComponent as RenderType_ShowImageComponent };
function View_ShowImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "showButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showImage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("Show")); _ck(_v, 6, 0, currVal_1); }); }
function View_ShowImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "Modal"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "modal-content"]], null, [[null, "clickOutsideMe"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutsideMe" === en)) {
        var pd_1 = (_co.onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.ClickOutsideMeDirective, [i1.ElementRef], null, { clickOutsideMe: "clickOutsideMe" }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ngx-image-viewer", [["class", "responsive-img"]], null, [["window", "keyup.ArrowRight"], ["window", "keyup.ArrowLeft"], [null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("window:keyup.ArrowRight" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).nextImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keyup.ArrowLeft" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).prevImage($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).onMouseOver() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_ImageViewerComponent_0, i4.RenderType_ImageViewerComponent)), i1.ɵdid(7, 114688, null, 0, i5.ImageViewerComponent, [[2, "config"]], { src: [0, "src"] }, null), i1.ɵpad(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 8, 0, _co.imageUrl); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.displayModel ? "block" : "none"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ShowImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "scroll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowImageComponent_1)), i1.ɵdid(11, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowImageComponent_2)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.imageList; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.displayModel; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("File Name")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("Show File")); _ck(_v, 7, 0, currVal_1); }); }
export function View_ShowImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "show-image", [], null, null, null, View_ShowImageComponent_0, RenderType_ShowImageComponent)), i1.ɵdid(1, 114688, null, 0, i7.ShowImageComponent, [i2.TranslateService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowImageComponentNgFactory = i1.ɵccf("show-image", i7.ShowImageComponent, View_ShowImageComponent_Host_0, { imageList: "imageList" }, {}, []);
export { ShowImageComponentNgFactory as ShowImageComponentNgFactory };
