import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../environments/environment'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'show-image',
	templateUrl: './show-image.component.html',
	styleUrls: ['./show-image.component.css']
})
export class ShowImageComponent implements OnInit {
	selectedLang = "العربيه";
	imageUrl: string;

	@Input()
	imageList: any[];
	files = []
	rootUrl = environment.rootUrl + '/media/';
	displayModel: boolean;
	constructor(public translate: TranslateService,private router: Router, ) {

		    translate.addLangs(["en", "ar"]);
		    const browserLang = translate.getBrowserLang();
		    this.translate.setDefaultLang('ar');





	}

	ngOnInit() {

	}
	showImage(file: string) {

		let fileExtension = file.split('.').reverse()[0]

		if (fileExtension == 'pdf') {
			this.displayModel = false;
			window.open('/etisalat/digital/show-pdf/' + file)

		}
		else {

			this.displayModel = true;
			this.imageUrl = this.rootUrl + file
		}


	}
	onClick($event) {
		if ($event.target.classList[0] == 'showButton') {

			this.displayModel = true;



		}
		else {
			this.displayModel = false;


		}
		//console.log(this.displayModel,$event.target.classList[0])

	};


}
