
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Observable, from } from 'rxjs';
import { MatTableModule, MatSortModule, MatTableDataSource, MatSort, MatPaginator } from '@angular/material'
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { FilterStatusChoices,FilterStatusChoicesEti } from './../status-comment/status-choices';
import { ngxCsv } from "ngx-csv/ngx-csv";
import { customerAllExportModel } from '../shared/customer-export-moi.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.css']
})


export class CustomersTableComponent implements OnInit {
  selectedLang = "العربيه"

  newStatus: string;
  //codedUserGroup = localStorage.getItem(btoa("userGroup"));
  //userGroup = atob(codedUserGroup);
  statusChoices = FilterStatusChoices;
  
  defaultFilter = 'pending';

  @Output() outputToMoiCustomerComponent = new EventEmitter<string>();

  public dataSource;

  dataLength = 70;
  userGroup ;

  displayedColumns = ['name','office_name', 'emirates_id', 'phone', 'status', 'updated_at', 'support_number','order_status','search']
  data: any;
  exportList: Observable<Object>;
  textDir: string;

  constructor(public translate: TranslateService,private userService?: UserService,private toastr?: ToastrService) { 
    
    translate.addLangs(["en", "ar"]);
    
    const browserLang =  this.translate.getDefaultLang();
    console.log("Browser Language customer table ", browserLang); 
    if(browserLang == 'ar'){
        this.textDir = 'rtl';
    }
    else{
      this.textDir = 'ltr';
    }  
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    if(!this.statusChoices.includes("Active case")){
      this.statusChoices.push("Active case");
    }
    
    if(!this.statusChoices.includes("Stopped case")){
      this.statusChoices.push("Stopped case");
  
    }
    

    this.userService.getCustomerTable().subscribe(response => {
      if (!response) {
        return;
      }
      this.dataLength = response.length
      this.dataSource = new MatTableDataSource(response);

      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator;

      //be default show the pending requests only
      let codedUserGroup = localStorage.getItem(btoa("userGroup"))
      let userGroup = atob(codedUserGroup)
      this.userGroup = userGroup;
      if (userGroup == 'moi') {
        this.doFilter(this.defaultFilter)
      }
      if (userGroup == "etisalat") {
       this.statusChoices = FilterStatusChoicesEti
     }
    })


  }

  downloadFile(status){
    
   console.log("Passing status to service " , status);
    this.exportList = this.userService.getNewExportData(status);
  
    this.exportList.subscribe(
      (data => this.fileDownload(data,status)),//console.log(data),
      error => console.log('Error downloading the file.', error),
      () => console.info('OK')
   
    );
 
  }

  fileDownload(data,status) {
    const blob = new Blob([data], { type: 'text/csv' });
    var downloadLink = document.createElement("a");
    const url= window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = status + Date.now() + "_Reports.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    //window.open(url);
  }

  
  sendEmiratesIdToParent(customerEmiratesID: string) {


    this.outputToMoiCustomerComponent.emit(customerEmiratesID);


  }


  public doFilter = (value: string) => {

    this.dataSource.filter = value.trim().toLocaleLowerCase();
  
  }

  exportData(value : string ){
    if(this.userGroup != 'moi' && this.userGroup != 'non_admin')
        this.userService.getNewExportData(value);
    else{
      this.userService.getExportedData(value);
    }

  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
}

  onSelectStatusChange() {


  }




}
