/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-pdf.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-extended-pdf-viewer/ngx-extended-pdf-viewer.ngfactory";
import * as i3 from "ngx-extended-pdf-viewer";
import * as i4 from "./show-pdf.component";
import * as i5 from "@angular/router";
var styles_ShowPdfComponent = [i0.styles];
var RenderType_ShowPdfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowPdfComponent, data: {} });
export { RenderType_ShowPdfComponent as RenderType_ShowPdfComponent };
export function View_ShowPdfComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-extended-pdf-viewer", [["delayFirstView", "1000"], ["height", "100vh"], ["language", "en"], ["useBrowserLocale", "true"]], null, null, null, i2.View_NgxExtendedPdfViewerComponent_0, i2.RenderType_NgxExtendedPdfViewerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxExtendedPdfViewerComponent, [], { delayFirstView: [0, "delayFirstView"], src: [1, "src"], height: [2, "height"], useBrowserLocale: [3, "useBrowserLocale"], language: [4, "language"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "1000"; var currVal_1 = _co.fileName; var currVal_2 = "100vh"; var currVal_3 = "true"; var currVal_4 = "en"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ShowPdfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-pdf", [], null, null, null, View_ShowPdfComponent_0, RenderType_ShowPdfComponent)), i1.ɵdid(1, 114688, null, 0, i4.ShowPdfComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowPdfComponentNgFactory = i1.ɵccf("app-show-pdf", i4.ShowPdfComponent, View_ShowPdfComponent_Host_0, {}, {}, []);
export { ShowPdfComponentNgFactory as ShowPdfComponentNgFactory };
