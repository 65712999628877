import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthGuard } from './auth/auth.guard';
import { UploadMultipleComponent } from './upload-multiple/upload-multiple.component'
import { MoiCustomersComponent } from './moi-customers/moi-customers.component'
import { AnonymousUserComponent } from './anonymous-user/anonymous-user.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { ThanksComponent } from './thanks/thanks.component';
import { StatusCommentComponent } from './status-comment/status-comment.component';
import { ShowPdfComponent } from './show-pdf/show-pdf.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminModuleComponent } from './admin-module/admin-module.component';

const routes: Routes = [



  { path: 'etisalat/digital/show-pdf/:fileName', component: ShowPdfComponent },
  { path: 'etisalat/digital/weblink/:userId', component: AnonymousUserComponent },
  { path: 'admin/:func', component: AdminModuleComponent , canActivate: [AuthGuard]},
  {path: 'changepassword', component: ChangePasswordComponent},
  





  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [

      { path: 'customer/upload', component: UploadMultipleComponent, },
      { path: 'etisalat/upload', component: MoiCustomersComponent, }
      

    ]
  },


  { path: 'status', component: StatusCommentComponent },
  { path: 'login', component: SignInComponent },
  

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'pagenotfound', component: NotFoundComponent },
  { path: 'completed/thanks', component: ThanksComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }