import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from './../../environments/environment';
;
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(userService, router, route, localeId, translate) {
        var _this = this;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.localeId = localeId;
        this.translate = translate;
        this.rootUrl = environment.rootUrl;
        this.securityVerified = false;
        this.tryingToLoginCount = 0;
        this.languageList = [
            { code: 'en', label: 'English' },
            { code: 'ar', label: 'عربى' },
        ];
        this.passwordChangeSuccess = false;
        this.passwordError = false;
        this.hide = true;
        this.hidenew = true;
        this.passwordErrorFinal = false;
        // Regex to check for a common password string - all based on 5+ length passwords
        this.commonPasswordPatterns = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;
        this.createSecurityAnswers = function (ownerFormValue) {
            if (_this.securityForm.valid) {
                console.log("Owner Form Value ", ownerFormValue);
                _this.executeOwnerCreation(ownerFormValue);
            }
        };
        translate.addLangs(["en", "ar"]);
        this.translate.setDefaultLang('ar');
        console.log("Browser Language  change password");
        this.textDir = 'rtl';
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.securityForm = new FormGroup({
            AnswerOne: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            AnswerTwo: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            AnswerThree: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            UserName: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            NewPassword: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            NewPassword2: new FormControl('', [Validators.required, Validators.maxLength(300)]),
        });
        this.passwordForm = new FormGroup({
            Password1: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            Password2: new FormControl('', [Validators.required, Validators.maxLength(300)]),
        });
        this.processSecurityQuestions();
    };
    Object.defineProperty(ChangePasswordComponent.prototype, "MinimumLength", {
        // Expected length of all passwords
        get: function () {
            return 5;
        },
        enumerable: true,
        configurable: true
    });
    //
    // Checks if the given password matches a set of common password
    //
    ChangePasswordComponent.prototype.isPasswordCommon = function (password) {
        return this.commonPasswordPatterns.test(password);
    };
    ChangePasswordComponent.prototype.checkPasswordStrength = function (password) {
        // Build up the strenth of our password
        var numberOfElements = 0;
        numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements;
        console.log(" checkPasswordStrength  Small Leeter =  ", numberOfElements);
        numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements; // Uppercase letters
        console.log(" checkPasswordStrength  Capital Letters=  ", numberOfElements);
        numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements; // Numbers
        console.log(" checkPasswordStrength  Numeric  Letters=  ", numberOfElements);
        numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements; // Special characters (inc. space)
        console.log(" checkPasswordStrength  Special  Letters=  ", numberOfElements);
        // Assume we have a poor password already
        var currentPasswordStrength = 0 /* Short */;
        // Check then strenth of this password using some simple rules
        if (password === null || password.length < this.MinimumLength) {
            currentPasswordStrength = 0 /* Short */;
        }
        else if (this.isPasswordCommon(password) === true) {
            currentPasswordStrength = 1 /* Common */;
        }
        else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
            currentPasswordStrength = 2 /* Weak */;
        }
        else if (numberOfElements === 3) {
            currentPasswordStrength = 3 /* Ok */;
        }
        else {
            currentPasswordStrength = 4 /* Strong */;
        }
        // Return the strength of this password
        return currentPasswordStrength;
    };
    ChangePasswordComponent.prototype.onSearchChange = function (event) {
        this.newPassword = event;
        if (this.newPassword === this.reEneteredPassword) {
            console.log("this.checkPasswordStrength(this.reEneteredPassword) ", this.checkPasswordStrength(this.reEneteredPassword));
            if (this.checkPasswordStrength(this.reEneteredPassword) === 3 || this.checkPasswordStrength(this.reEneteredPassword) === 4) {
                console.log("Password matched so error will be false");
                this.passwordError = false;
            }
            else {
                this.passwordError = true;
                this.passwordErrorMsg = "Special character or number or character missing";
                console.log(this.reEneteredPassword);
                console.log("this.checkPasswordStrength(event) ", this.checkPasswordStrength(this.reEneteredPassword));
            }
        }
        else {
            this.passwordErrorMsg = "Password does not match";
            this.passwordError = true;
        }
    };
    ChangePasswordComponent.prototype.onSearchChangeNewPassword = function (event) {
        this.reEneteredPassword = event;
    };
    ChangePasswordComponent.prototype.changePasswordFunc = function (event) {
        console.log("Change Password function");
    };
    ChangePasswordComponent.prototype.Cancel = function () {
        console.log("Cancelled change password");
        this.router.navigate(['/']);
    };
    ChangePasswordComponent.prototype.executeOwnerCreation = function (securityAnswersObj) {
        var _this = this;
        var securityQsObj = {
            AnswerOne: securityAnswersObj.AnswerOne,
            AnswerTwo: securityAnswersObj.AnswerTwo,
            AnswerThree: securityAnswersObj.AnswerThree,
            UserName: securityAnswersObj.UserName,
            NewPassword: securityAnswersObj.NewPassword,
            NewPassword2: securityAnswersObj.NewPassword2
        };
        console.log("answer 1 = ", securityQsObj.AnswerOne);
        console.log("answer 2 = ", securityQsObj.AnswerTwo);
        console.log("answer 3 = ", securityQsObj.AnswerThree);
        if (securityQsObj.NewPassword != securityQsObj.NewPassword2) {
            this.passwordErrorFinal = true;
            this.passwordChangeSuccess = true;
            this.securityVerified = false;
        }
        var securityAnswerrequest = this.userService.changePassword(securityQsObj);
        securityAnswerrequest.subscribe(function (response) {
            console.log("Response form Security Answer verify API", response);
            if ("message" in response) {
                console.log("The password reset was sucess");
                _this.passwordChangeSuccess = true;
                _this.securityVerified = true;
            }
            else {
                if ("error" in response) {
                    console.log("The password reset was an error");
                    _this.passwordErrorFinal = true;
                    _this.passwordChangeSuccess = true;
                    _this.securityVerified = false;
                }
            }
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
            _this.passwordErrorFinal = true;
            _this.passwordChangeSuccess = true;
            _this.securityVerified = false;
        });
    };
    ChangePasswordComponent.prototype.processSecurityQuestions = function () {
        var _this = this;
        this.securityQrequest = this.userService.getSecurityQuestions();
        this.securityQrequest.subscribe(function (response) {
            console.log("Printing test Response = ", response);
            _this.questionOne = response[0]["question"];
            _this.questionTwo = response[1]["question"];
            _this.questionThree = response[2]["question"];
            console.log(" questionOne = ", _this.questionOne);
            console.log(" questionTwo = ", _this.questionTwo);
            console.log(" questionThree = ", _this.questionThree);
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
            _this.passwordError = true;
        });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
