<mat-sidenav-container>
  <!--
  <mat-sidenav #start mode="side" fixedViewport="true" opened>
  
      <mat-nav-list>
        <ul>
          <li *ngIf="userGroup == 'etisalat' "><button mat-list-item routerLink="/home/etisalat/upload"> <img src="assets/img/settings-green.png" class="sidenav-icon"> <label i18n>etisalat Upload</label>
        </button></li>
      
          <li *ngIf="userGroup == 'customer' "><button mat-list-item routerLink="/home/customer/upload"> <img src="assets/img/settings-green.png" class="sidenav-icon"> <label i18n>Customer Upload</label>
        </button></li>                 
        </ul>
      </mat-nav-list>

    </mat-sidenav>
  -->
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <router-outlet name="popup"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

