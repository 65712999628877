// errors-handler.ts
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class ErrorsHandler implements ErrorHandler {
    handleError(error: Error | HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            // Server or connection error happened
            if (!navigator.onLine) {
                // Handle offline error
                alert("Error :" + "kindly note that you have lost the connection and changes will not be saved");
            } 
            else if(error.status===401){
                alert('please Login first.')
            }
            
            else {
                // Handle Http Error (error.status === 403, 404...)
                alert("Error :" + error.message);
            }
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)     
            alert("Error :" + "there is error with the web aplication kindly contact the admin");
        }
        // Log the error anyway
        console.error('It happens: ', error);
    }
}