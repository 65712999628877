import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private snackBar: MatSnackBar ) { }

  ngOnInit() {

       // this.openSnackBar (" success uploaded the file ", "goto home page ") ;

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(" success uploaded the file ", "goto home page ", {
      duration: 2000,
    });



  }

}
