import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpResponse, HttpRequest, 
         HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { of } from 'rxjs/observable/of';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { environment } from './../../environments/environment';

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}


export interface ERROR_ITEM{
  case_id : string;
  errors_message: string;
 
}


@Component({
  selector: 'app-upload-customer',
  templateUrl: './upload-customer.component.html',
  styleUrls: ['./upload-customer.component.css'],
  animations: [
    trigger('fadeInOut', [
          state('in', style({ opacity: 100 })),
          transition('* => void', [
                animate(300, style({ opacity: 0 }))
          ])
    ])
]

})
export class UploadCustomerComponent implements OnInit {
 /** Link text */
 @Input() status;
 @Input() forStatusChange = false;;
 /** Name used in form which will be sent in HTTP request. */
 @Input() param = 'file';
 /** Target URL for file uploading. */
 private baseApiUrl = environment['rootUrl'] + '/import/upload'
 @Input() target = this.baseApiUrl ;
 /** File extension that accepted, same as 'accept' of <input type="file" />. 
     By the default, it's set to 'image/*'. */
 @Input() accept = '*';
 /** Allow you to add handler after its completion. Bubble up response text from remote. */
 @Output() complete = new EventEmitter<string>();

 files: Array<FileUploadModel> = [];
 fileToUpload: File;
 
 dataSource :MatTableDataSource<ERROR_ITEM>;
 
 @ViewChild(MatPaginator) paginator: MatPaginator;
 displayedColumns: string[] = ['case_id', 'error_message'];
 
 errorItems : any = [];
 success = 'Not Initialized';
 constructor(private _http: HttpClient) { }

 ngOnInit() {
 }

 onClick() {
       console.log("Selected Status = ", this.status);
       if(this.forStatusChange){
         if(this.status == 'ceased'){
            this.target = environment['rootUrl'] + '/update_case/ceased';
         }
         else{
            this.target = environment['rootUrl'] + '/update_case/bulk';
         }
       }
       console.log("For status change = ", this.forStatusChange);
       const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
       fileUpload.onchange = () => {
             for (let index = 0; index < fileUpload.files.length; index++) {
                   const file = fileUpload.files[index];
                   this.files.push({ data: file, state: 'in', 
                     inProgress: false, progress: 0, canRetry: false, canCancel: true });
             }
             this.uploadFiles();
       };
       fileUpload.click();
 }

 cancelFile(file: FileUploadModel) {
       file.sub.unsubscribe();
       this.removeFileFromArray(file);
 }

 retryFile(file: FileUploadModel) {
       this.uploadFile(file);
       file.canRetry = false;
 }

 private uploadFile(file: FileUploadModel) {
  var errors ;
       this.fileToUpload = file.data;
       const fd = new FormData();
       fd.append("file_name", this.fileToUpload, this.fileToUpload.name);
       if(this.forStatusChange){
        fd.append("status",this.status);
       }
       else{
          fd.append("region","Dubai");
       }
       //fd.append("status",'1');
       //fd.append("user",'1');
       
       console.log(file);
       const req = new HttpRequest('POST', this.target, fd, {
             reportProgress: true
       });

      

       file.inProgress = true;
       file.sub = this._http.request(req).pipe(
             map(event => { switch (event.type) {
                         case HttpEventType.UploadProgress:
                               file.progress = Math.round(event.loaded * 100 / event.total);
                               break;
                         case HttpEventType.Response:
                               return event;
                   }
             }),
             tap(message => { }),
             last(),
             catchError((error: HttpErrorResponse) => {
                   file.inProgress = false;
                   file.canRetry = true;
                   return of(`${file.data.name} upload failed.`);
             })
       ).subscribe(
             (event: any) => {
                   if (typeof (event) === 'object') {
                         this.errorItems = [];
                         console.log("Event Body = " , event.body);
                         console.log("Sucess = ", event.body["success"]);
                         this.success = event.body["success"];
                        
                        
                         if(this.success == 'false'){
                          console.log("Error = ", event.body["error"]);
                           errors = event.body["error"];
                           console.log("Error = ", event.body["error"]);
                            for (let err of errors) {
                              this.errorItems.push({"case_id":err["case_id"],"error_message":err["error_message"] }); 
                          }
                        }
                         this.dataSource = new MatTableDataSource<ERROR_ITEM>(this.errorItems);
                         console.log("ERROR_DATA = ", this.errorItems);
                         this.complete.emit(event.body);
                        
                        
                      }
                    }
                      
        );
}

 private uploadFiles() {
       const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
       fileUpload.value = '';

       this.files.forEach(file => {
             this.uploadFile(file);
       });
 }

 private removeFileFromArray(file: FileUploadModel) {
       const index = this.files.indexOf(file);
       if (index > -1) {
             this.files.splice(index, 1);
       }
 }

 private onFileComplete(){
       console.log("File Uploaded successfully");
       
 }


}
