<div class="navbar-fixed" class="headerDiv">
  <nav class="nav-wrapper-first" class="headerDiv">
    <div>
      <a *ngIf="selectedLang == 'en'"><img src="assets/img/ministry-of-interial.png"
          class="sidenav-icon left-logo"></a>
      <a *ngIf="selectedLang != 'en'"><img src="assets/img/ministry-of-interial-ar.png"
          class="sidenav-icon left-logo"></a>
      <ul class="right">

        <img src="assets/img/hussantuk.png" class="sidenav-icon right-logo">
      </ul>
      <ul class="right">


        <img src="assets/img/etisalat_logo.png" class="sidenav-icon etisalat-logo"> 
      </ul>
    </div>
  </nav>
</div>
<nav>

  <nav>
    <div class="nav-wrapper">

      <ul id="nav-mobile" class="right">
        <li>

        </li>
      </ul>
      <ul id="nav-mobile" class="left">
        <li>

        </li>
      </ul>

    </div>
  </nav>


  <mat-card class="my-card center" >
    <mat-card-content  >

      <div >
        <h3 class="header__title" data-lead-id="header-title">{{'Customer.Completed' | translate}}</h3>

        <h5 class="main-content__body" data-lead-id="main-content-body">
          {{'Customer.Thank_you_for_submitting_the_documents' | translate}}<br>
          {{'Customer.details_will_be_reviewed_by_MOCD' | translate}}</h5>

        <img src="assets/correctImage.png" alt="Done" height="35%" width="35%" style="margin-bottom: 2%">

      </div>



    </mat-card-content>

  </mat-card>