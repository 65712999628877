import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { UserService } from '../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material'
import { CustomersTableComponent } from "../customers-table/customers-table.component"
import { AdminStatusChoices, EtisalatStatusChoices, MoiStatusChoices, } from './status-choices'
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-status-comment',
  templateUrl: './status-comment.component.html',
  styleUrls: ['./status-comment.component.css']
})
export class StatusCommentComponent implements OnInit {
  selectedLang = "العربيه";
  newStatus = 'None';
  statusChoices: any[];
  enableRejectReason: boolean;
  disableStatusChangeSubmitBtn: boolean = true;
  isError: boolean = false;
  @Input()
  customerId: number;
  @Input()
  customerDetails = [];
  @Output() statusChanged = new EventEmitter();
  isShowStatus = true;
  textDir: string;


  constructor(public translate: TranslateService,private userService: UserService, private router: Router, private snackBar: MatSnackBar) { 
    translate.addLangs(["en", "ar"]);
    const browserLang = translate.getDefaultLang();
    console.log("Browser Language status ", browserLang);
    
    
    if(browserLang == 'ar'){
        this.textDir = 'rtl';
    }
    else{
      this.textDir = 'ltr';
    }
    
  }


  onSelectStatusChange() {

    if (this.newStatus == 'rejected' || this.newStatus == 'rejected finally') {
      this.enableRejectReason = true;
      this.disableStatusChangeSubmitBtn = true;
      return 'Nothing to do skipe'
    };
    this.enableRejectReason = false;
    this.disableStatusChangeSubmitBtn = false;


  };

  changeStatus(comment) {
    let statusData: {};
    if (this.newStatus == 'rejected' || this.newStatus == 'rejected finally') {
      statusData = { 'status': this.newStatus, 'reject_reason': comment, 'comment': '' }
    }
    else {
      statusData = { 'status': this.newStatus, 'comment': comment, 'reject_reason': '' }
    }

    this.userService.putChangeStatus(this.customerId, statusData).subscribe((data: any) => {
      this.customerDetails[0].status = this.newStatus;
      this.disableStatusChangeSubmitBtn = true;

      //set output event emmiter data to pass it into customers table component to change the status in datasource there.
      let statusChangedData = { emirates_id: this.customerDetails[0].emirates_id, status: this.newStatus }
      this.statusChanged.emit(statusChangedData);


      //show success message.
      this.openSnackBar('Status has been changed successfully to ' + this.newStatus)
      this.newStatus = 'None'

    },
      (err: HttpErrorResponse) => {
        this.isError = true;
        console.log(err);
      });

  }

  showStatus(){
    let codedUserGroup = localStorage.getItem(btoa("userGroup"))
    let userGroup = atob(codedUserGroup) 
    
    if (userGroup == 'non_admin'){
      this.isShowStatus =  false
    }
    this.isShowStatus =  true;
  }



  validateRejectReason(rejectReason) {

    if (rejectReason == '') {

      this.disableStatusChangeSubmitBtn = true;
      this.openSnackBar('Please enter a reject reason!')
    }
    else {
      this.disableStatusChangeSubmitBtn = false;
    }
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, { duration: 4000, });
  }

  ngOnInit() {
    let codedUserGroup = localStorage.getItem(btoa("userGroup"))
    let userGroup = atob(codedUserGroup)

    if (userGroup == 'etisalat') {
      this.statusChoices = EtisalatStatusChoices;
    }
    else if (userGroup == 'moi') {
      this.statusChoices = MoiStatusChoices;
    }
    else if (userGroup == 'admin')  {
      this.statusChoices = AdminStatusChoices;
    }
    else if (userGroup == 'non_admin')  {
      this.statusChoices = AdminStatusChoices;
      this.isShowStatus =  false;
    }
    else if (userGroup == 'documentview')  {
      this.statusChoices = AdminStatusChoices;
      this.isShowStatus =  false;
    }
    else {
      this.router.navigate(['404']);
    }
  }
}
