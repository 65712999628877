
  <div class="navbar-fixed" class="headerDiv" >
      <nav class="nav-wrapper-first"  class="headerDiv" style="background: #fcfcff; box-shadow: inherit;">
        <div >

          <a *ngIf="selectedLang == 'english'"  ><img src="assets/img/ministry-of-interial.png" class="sidenav-icon left-logo" ></a>
          <a *ngIf="selectedLang != 'english'"  ><img src="assets/img/ministry-of-interial-ar.png" class="sidenav-icon left-logo" ></a>
        
            <ul class="right">
          
                <mat-form-field class="lang_select text_font">
                    <mat-label class="text_font">{{'Customer.select_you_Language' | translate}}</mat-label>
                    <mat-select class="text_font" [(value)]="selectedLang" name="action" 
                    (selectionChange)="switchLanguage()">
                
                      <mat-option value="english" class="text_font" >English</mat-option>
                      <mat-option value="العربيه" class="text_font">العربيه</mat-option>
                    </mat-select>
                  </mat-form-field>
              </ul>

            
          <ul class="right">          
            <img src="assets/img/hussantuk.png" class="right-logo"> 
          </ul>
          <ul class="right">         
              <img src="assets/img/etisalat_logo.png" class="etisalat-logo"> 
            </ul>
        </div>
      </nav>
    </div>

<nav style="background-color: transparent; box-shadow: none;">
  <div class="mat-card" style="box-shadow: none; ">
    <a class="brand-logo center title_font">
      {{'Customer.TITLE' | translate}} </a>
      
  </div>
</nav>
<div style="text-align: center; font-size: 2vw; font-family: cronos pro; margin: -25px !important;">
  <p>{{'Customer.subtitle' | translate}}</p>
</div>


<div >
  <mat-toolbar *ngIf="isvalidUser">
    <span class="brand-logo center sub_title_text_font sub_title_text" i18n>{{'Customer.question' | translate}} </span>
  </mat-toolbar>

<!--   <mat-toolbar *ngIf="!isvalidUser">
    <span class="text_font"  i18n>{{'Customer.Emirates_ID_Validation' | translate}} </span>
  </mat-toolbar> -->
  <mat-card class="my-card" style="box-shadow:none; padding: 80px;">
    <mat-card-content>

  <div class = "container" >

  <p class="text-center weblink_doc">{{'Customer.remarks' | translate}} </p>
  </div>


      <div *ngIf="isError" class="red-text center error-message" i18n>
        <i class="material-icons text_font">error</i> {{'Customer.Error_With_this_emirates_id' | translate}}
      </div>

      <div *ngIf="!isvalidUser" class="center">

        <form #loginForm="ngForm" class="row  transparent" (ngSubmit)="OnSubmit(EmiratesId.value)" id="myDev">
          <div class="row" >
            <div class="input-field row s7">
              <input class="center" type="text" #EmiratesId ngModel name="EmiratesId" required>
              <label for="icon_prefix text_font" i18n>{{'Customer.Enter_Your_emirates_id' | translate}}</label>
              <p style="color: #848789; font-size=12px; padding-top: -50 px; padding-bottom: 20px;">{{'Customer.example' | translate}} </p>
              <button [disabled]="!loginForm.valid" class="btn btn-submit  center text_font" type="submit"
                i18n style="height: 64px; width: 240px;">{{'Customer.submit' | translate}}</button>
            </div>

          </div>
        </form>

      </div>

<!-- 
      <mat-divider></mat-divider> -->


      <div *ngIf="isvalidUser">
        <mat-card class="upload-card">
          <mat-card-header>
            <mat-card-title i18n>{{'Customer.Details' | translate}}</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>

            <div class='wlc-ph text_font'>
              <span class='welc text_font' i18n>{{'Customer.Welcome' | translate}}</span> {{userName}} <br> <span class='welc text_font'
                i18n>{{'Customer.Your_registered_phone_number_is' | translate}} </span> <span class='ph text_font'
                i18n>{{phoneNumber}}</span>

              <button class="waves-effect waves-light btn-small  text_font" (click)='onEditClk();'>
                <i class="material-icons left text_font"> settings</i>
                {{'Customer.Edit' | translate}}
              </button>
              
              <div *ngIf='editPh'>
                <div class="row">
                  <form class="col s12" #editForm='ngForm' (ngSubmit)="updatePhone(editForm.value)">
                    <div class="row">
                      <div class="input-field col s9 text_font">
                        <i class="material-icons prefix" i18n>mode_edit</i>
                        <input id="phone" #phone ngModel name='phone' pattern='[0-9]{12}' placeholder=""
                          required type="text" class="text_font center" style="width:90%">
                        <label for="phone" class="text_font"  i18n>{{'Customer.Phone_Number_+971xxx-xxx-xxx' | translate}}</label>
                        <button class='btn text_font' type='submit'
                          [disabled]='!editForm.valid'>{{'Customer.save' | translate}}</button>
                      </div>
                    </div>
                  </form>

                </div>

              </div>


            </div>
          </mat-card-content>
        </mat-card>


        <div *ngFor="let card of cards">
          <mat-card class="upload-card">
            <mat-card-header>
              <div>
              <mat-card-title i18n>{{card.title}}</mat-card-title>  
              <mat-card-subtitle>MOCD subtitle</mat-card-subtitle>  
              </div>          
            </mat-card-header>
            <mat-card-content>
              <app-upload-multiple  [emiratesId]='userEmiratesId' [userId]='userID' [uploaderType]='customer'>
              </app-upload-multiple>
            </mat-card-content>
          </mat-card>

        </div>
      </div>


    </mat-card-content>
  </mat-card>
  
</div>