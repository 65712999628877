import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { Observable } from 'rxjs';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userClaims: any;
  dialogRef: any;
  dialog: any;
  overlay: any;
  dialogueOpened : boolean = true;

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit() {
 
  }


  Logout() {
    //localStorage.removeItem('userToken');
    this.router.navigate(['/login']);
  }


}

