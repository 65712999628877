<div *ngIf="isShowStatus">

<div  dir="{{textDir}}">

	<mat-form-field class='select-btn' >
		<mat-label style = 'font-size: 13px;' i18n>{{ 'Status' | translate }}</mat-label>
		<mat-select style = 'font-size: 13px;' [(value)]="newStatus">
			<mat-option style = 'font-size: 13px;' i18n>{{ 'Change Status to' | translate }} </mat-option>
			<div *ngFor='let status of statusChoices'>
				<mat-option [value]='status' (click)=onSelectStatusChange()>
					{{status | translate}}
				</mat-option>
			</div>
		</mat-select>
	</mat-form-field>
</div>

<div *ngIf="!enableRejectReason" dir="{{textDir}}">

	<mat-form-field class="example-full-width">
		<textarea #comment matInput required placeholder="{{ 'Leave a comment' | translate }}">
    	</textarea>


	</mat-form-field>
	<button [disabled]='disableStatusChangeSubmitBtn' required (click)="changeStatus(comment.value);comment.value=''"
		class="btn  btn-submit">{{ 'submit' | translate }}
	</button>
</div>



<div *ngIf="enableRejectReason">
	<form class="example-form" #rejectForm>
		<mat-form-field class="example-full-width">
			<input matInput placeholder="Please enter the reject reason" required #rejectReason
				(change)="validateRejectReason(rejectReason.value)">
			<button [disabled]='rejectReason.value=="" ' type="submit" required
				(click)="changeStatus(rejectReason.value);rejectReason.value=''" i18n class="btn btn-submit">
				{{ 'submit' | translate }}
			</button>

		</mat-form-field>
	</form>
</div>
</div>