import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {AdminStatusChoices} from '../status-comment/status-choices';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-admin-module',
  templateUrl: './admin-module.component.html',
  styleUrls: ['./admin-module.component.css'],
  providers:[NotifierService]
})
export class AdminModuleComponent implements OnInit {
 upload = false;
 update = false;
 statusSelected = 'null';

 statusValues = AdminStatusChoices;
 links = ["Upload Customer","Update Status","Remove beneficiary"];
 activeLink: String;
 statusConfirmed = false;  

 firstFormGroup = new FormControl();

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  success: boolean;
  private notifierTR: NotifierService;

  constructor(private route: ActivatedRoute,notifierTR: NotifierService) { 
    this.notifierTR = notifierTR; 
 
  }

  ngOnInit() {
    
    console.log("UserGroup = ", localStorage.getItem(btoa("userGroup")));

    this.route.paramMap.subscribe(
      params => {

        let func_url = params.get('func');

        console.log("Functionality = " , func_url);
        if (func_url == 'upload'){
          this.upload = true;
        }
        else{
          this.update = true;
        }

      }

    );

  }

  onFileComplete(event){
    console.log("File Uploaded successfully in home TS in home");
    console.log("Event emitted = ", event);
    console.log("Sucess = ", event["success"]);
    this.success = event["success"];
    
   if(this.success == true){
    this.notifierTR.notify( 'info' , "File uploaded successfully.");  
    this.success = true;
   }
   else{
    this.success = false;
   this.notifierTR.notify( 'error' , "File uploaded successfully with errors. Please check for error on the screen if any");
   }
}

setDelay(notifierTR,msg,time,id) {
  setTimeout(function(){
    notifierTR.notify( 'info' ,  msg, id);
    console.log("--------------Notifying for ID -----------" , id);
  }, time);
}

  onClick(link: String){
    this.activeLink = link;
    this.success = false;
    this.statusSelected = null;
    console.log("Active Link == ",  this.activeLink);
    
  }

  updateStatus(){
  
    console.log("Current selection is ",this.statusSelected );
  }


  enableUpload(){
    this.statusConfirmed = true;
  }

}
