import { OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { UserService } from '../shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { CustomersTableComponent } from '../customers-table/customers-table.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { FormGroup, FormControl, Validators } from '@angular/forms';
var ɵ0 = {};
var MoiCustomersComponent = /** @class */ (function () {
    function MoiCustomersComponent(cdr, localeId, translate, userService, toastr, snackBar, dialog, overlay, data) {
        this.cdr = cdr;
        this.localeId = localeId;
        this.translate = translate;
        this.userService = userService;
        this.toastr = toastr;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.overlay = overlay;
        this.data = data;
        this.isError = false;
        this.files = [];
        this.customerDetails = [];
        this.isShowStatus = false;
        this.isShowFiles = false;
        this.imageURL = 'None';
        this.rootUrl = 'http://10.0.147.69:8090/media/';
        this.searchPlaceHolder = 'Search with emirates ID';
        this.selectedLang = "العربيه";
        this.outputToCustomerTable = new EventEmitter();
        this.dialogueOpened = true;
        translate.addLangs(["en", "ar"]);
        var browserLang = this.translate.getDefaultLang();
        console.log("Browser Language moi customer", browserLang);
        if (browserLang == 'ar') {
            this.textDir = 'rtl';
        }
        else {
            this.textDir = 'ltr';
        }
    }
    MoiCustomersComponent.prototype.ngOnDestroy = function () {
        console.log('destroyed');
        //localStorage.removeItem(btoa("userGroup"))
        //localStorage.removeItem(btoa("userToken"))
        console.log('destroyed');
    };
    MoiCustomersComponent.prototype.ngOnInit = function () {
        this.showStatus();
    };
    MoiCustomersComponent.prototype.ngAfterViewChecked = function () {
        //your code to update the model
        var browserLang = this.translate.getDefaultLang();
        console.log("Browser Language moi customer", browserLang);
        if (browserLang == 'ar') {
            this.textDir = 'rtl';
        }
        else {
            this.textDir = 'ltr';
        }
        this.cdr.detectChanges();
    };
    MoiCustomersComponent.prototype.openDialogSecurity = function () {
        if ((this.dialogRef != null)) {
            this.dialogRef.close();
            this.dialogRef.afterClosed().subscribe(function (result) {
            });
            this.dialogRef = null;
        }
        else
            (this.dialogRef == null);
        {
            console.log("Disabled Value " + this.dialogueOpened);
            this.dialogRef = this.dialog.open(DialogOverviewExampleDialogActionsSecurity, {
                height: "92%",
                width: "50%",
                scrollStrategy: this.overlay.scrollStrategies.noop(),
                autoFocus: false,
                data: {},
                disableClose: true
            });
            this.dialogRef.afterClosed().subscribe(function (result) {
                console.log("Dialog Comment:", result);
                //console.log("Dialog output:", result.data)
            });
        }
    };
    MoiCustomersComponent.prototype.showStatus = function () {
        var _this = this;
        var codedUserGroup = localStorage.getItem(btoa("userGroup"));
        var userGroup = atob(codedUserGroup);
        if (userGroup == 'non_admin') {
            this.isShowStatus = false;
            this.selectedIndex = 1;
        }
        else if (userGroup == 'documentview') {
            this.isShowStatus = false;
            this.isShowFiles = false;
            this.selectedIndex = 1;
        }
        else {
            this.isShowStatus = true;
            this.isShowFiles = true;
            this.selectedIndex = 0;
        }
        console.log("Admin", this.isShowStatus);
        this.userService.getSecurityAnswers().subscribe(function (data) {
            console.log(data);
            if (data.length == 3) {
                console.log("Already answered security question");
            }
            else {
                //setTimeout(() => this.openDialogSecurity());
                _this.openDialogSecurity();
            }
        }, function (err) {
            _this.isError = true;
            console.log(err);
        });
    };
    MoiCustomersComponent.prototype.OnSubmit = function (CustomrID) {
        var _this = this;
        this.isError = false;
        this.isShowStatus = true;
        this.isShowFiles = true;
        this.selectedIndex = 0;
        this.userService.getCustomerDetailes(CustomrID).subscribe(function (data) {
            console.log(data);
            console.log("this.selectedIndex ", _this.selectedIndex);
            if (data["order_status"] == 'Ceased' || data["order_status"] == 'ceased' || data["order_status"] == "موقوفة"
                || data["order_status"] == 'المساعدة موقوفة' || data["order_status"] == 'Stopped case') {
                _this.isShowStatus = false;
                _this.selectedIndex = 1;
            }
            _this.files = [];
            if (_this.selectedIndex == 1) {
                _this.getFiles(CustomrID);
            }
            _this.customerDetails = [];
            _this.customerDetails.push(data);
        }, function (err) {
            debugger;
            _this.isError = true;
            console.log(err);
        });
    };
    MoiCustomersComponent.prototype.getEmiratesIDFromSearchTable = function (customerEmiratesID) {
        if (customerEmiratesID) {
            this.searchPlaceHolder = customerEmiratesID;
            this.OnSubmit(customerEmiratesID);
        }
    };
    //check if the clicked tab is show files it will call the files. 
    MoiCustomersComponent.prototype.tabChanged = function ($event, customerId) {
        console.log("Tab changes event ", $event.tab.textLabel);
        if ($event.tab.textLabel == 'Show Files' || $event.tab.textLabel == 'اظهار الملفات') {
            this.getFiles(customerId);
        }
        else if ($event.tab.textLabel == 'Comments' || $event.tab.textLabel == "ملاحظات") {
            this.getAllComments(customerId);
        }
    };
    MoiCustomersComponent.prototype.getFiles = function (customerID) {
        var _this = this;
        this.files = [];
        this.userService.getCustomerFile(customerID).subscribe(function (data) {
            //this.files = data;
            data.forEach(function (file) {
                console.log(Object.values(_this.files));
                if (Object.values(_this.files).length == 0) {
                    _this.files.push(decodeURIComponent(file.file));
                }
                else {
                    if (!(decodeURIComponent(file.file) in _this.files))
                        console.log(decodeURIComponent(file.file));
                    {
                        _this.files.push(decodeURIComponent(file.file));
                    }
                }
            });
        }, function (err) {
            _this.isError = true;
            console.log(err);
        });
    };
    //showFile(fileURL){
    //     //window.open("http://127.0.0.1:8090"+fileURL, "_blank");
    //     //console.log(this.imageURL)
    //     this.imageURL=(this.rootUrl+fileURL)
    //
    //     console.log(this.imageURL.toString() )
    //}
    MoiCustomersComponent.prototype.getAllComments = function (CustomrID) {
        //
        var _this = this;
        this.userService.getCommentsOnCustomer(CustomrID).subscribe(function (data) {
            _this.customerComments = data;
        }, function (err) {
            console.log(err);
        });
    };
    MoiCustomersComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, { duration: 4000, });
    };
    MoiCustomersComponent.prototype.updateStatus = function (changedStatusData) {
        this.CustomersTableComponentInstance.dataSource.filter = changedStatusData.emirates_id;
        this.CustomersTableComponentInstance.dataSource.filteredData[0].status = changedStatusData.status;
        //console.log(this.dataSource.filteredData[0].name='sssssssssssssss')
        this.CustomersTableComponentInstance.dataSource.filter = '';
    };
    ;
    return MoiCustomersComponent;
}());
export { MoiCustomersComponent };
var DialogOverviewExampleDialogActionsSecurity = /** @class */ (function () {
    function DialogOverviewExampleDialogActionsSecurity(cdr, translate, dialogRef, data, http, userService) {
        var _this = this;
        this.cdr = cdr;
        this.translate = translate;
        this.dialogRef = dialogRef;
        this.data = data;
        this.http = http;
        this.userService = userService;
        this.selectedLang = "العربيه";
        this.textDir = 'ltr';
        this.hasError = function (controlName, errorName) {
            return _this.securityForm.controls[controlName].hasError(errorName);
        };
        this.createSecurityAnswers = function (ownerFormValue) {
            if (_this.securityForm.valid) {
                console.log("Owner Form Value ", ownerFormValue);
                _this.executeOwnerCreation(ownerFormValue);
            }
        };
        this.processSecurityQuestions();
        var browserLang = translate.getDefaultLang();
        if (browserLang == 'ar') {
            this.textDir = 'rtl';
        }
        else {
            this.textDir = 'ltr';
        }
    }
    DialogOverviewExampleDialogActionsSecurity.prototype.ngAfterViewChecked = function () {
        //your code to update the model
        var browserLang = this.translate.getDefaultLang();
        console.log("Browser Language moi customer", browserLang);
        if (browserLang == 'ar') {
            this.textDir = 'rtl';
        }
        else {
            this.textDir = 'ltr';
        }
        this.cdr.detectChanges();
    };
    DialogOverviewExampleDialogActionsSecurity.prototype.ngOnInit = function () {
        this.securityForm = new FormGroup({
            AnswerOne: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            AnswerTwo: new FormControl('', [Validators.required, Validators.maxLength(300)]),
            AnswerThree: new FormControl('', [Validators.required, Validators.maxLength(300)]),
        });
    };
    DialogOverviewExampleDialogActionsSecurity.prototype.executeOwnerCreation = function (securityAnswersObj) {
        var securityQsObj = {
            AnswerOne: securityAnswersObj.AnswerOne,
            //dateOfBirth: ownerFormValue.dateOfBirth,
            AnswerTwo: securityAnswersObj.AnswerTwo,
            AnswerThree: securityAnswersObj.AnswerThree
        };
        console.log("answer 1 = ", securityQsObj.AnswerOne);
        console.log("answer 2 = ", securityQsObj.AnswerTwo);
        console.log("answer 3 = ", securityQsObj.AnswerThree);
        this.dialogRef.close({ event: 'close', data: {} });
        var securityAnswerrequest = this.userService.addSecurityAnswers(1, securityQsObj.AnswerOne);
        securityAnswerrequest.subscribe(function (response) {
            console.log("Response form Security Answer Set API", response);
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
        });
        var securityAnswerrequest = this.userService.addSecurityAnswers(2, securityQsObj.AnswerTwo);
        securityAnswerrequest.subscribe(function (response) {
            console.log("Response form Security Answer Set API", response);
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
        });
        var securityAnswerrequest = this.userService.addSecurityAnswers(3, securityQsObj.AnswerThree);
        securityAnswerrequest.subscribe(function (response) {
            console.log("Response form Security Answer Set API", response);
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
        });
    };
    DialogOverviewExampleDialogActionsSecurity.prototype.processSecurityQuestions = function () {
        var _this = this;
        this.securityQrequest = this.userService.getSecurityQuestions();
        this.securityQrequest.subscribe(function (response) {
            console.log("Printing test Response = ", response);
            _this.questionOne = response[0]["question"];
            _this.questionTwo = response[1]["question"];
            _this.questionThree = response[2]["question"];
            console.log(" questionOne = ", _this.questionOne);
            console.log(" questionTwo = ", _this.questionTwo);
            console.log(" questionThree = ", _this.questionThree);
        }, function (errorMessage) {
            console.log("Error found", errorMessage);
        });
    };
    DialogOverviewExampleDialogActionsSecurity.prototype.Cancel = function () {
        this.dialogRef.close({ event: 'close', data: {} });
    };
    DialogOverviewExampleDialogActionsSecurity.prototype.OnClickSchedule = function () {
        console.log("IN confirm of test review");
        this.dialogRef.close({ event: 'close', data: {} });
    };
    return DialogOverviewExampleDialogActionsSecurity;
}());
export { DialogOverviewExampleDialogActionsSecurity };
export { ɵ0 };
