<mat-dialog-content style="height:100% !important">
        <form [formGroup]="securityForm"  autocomplete="off"   fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px"  #editForm="ngForm" id="editForm" (ngSubmit)="createSecurityAnswers(securityForm.value)">
        <p class="textHeading2" i18n dir="{{textDir}}">{{ 'Please Answer The Below Security Questions To Proceed' | translate }}</p>
        <p class="textHeading8" i18n dir="{{textDir}}">{{ 'Hint: The answers will be used for verification in case of a password change' | translate }}</p>
    <mat-card-content class="mat-card-content"  dir="{{textDir}}" style="width:100%;height: 100%;margin-bottom: 2%;overflow:unset !important">
        <div class="row" dir="{{textDir}}">
                <mat-form-field class="row_align input-field-sec col s8 offset-s3" appearance="none">
                <label  i18n class="login_align_sec" i18n >{{questionOne | translate}}</label>
                <input matInput  i18n type="text" dir="{{textDir}}" class="login_text_sec  login_align_sec"  formControlName="AnswerOne"   name="AnswerOne" required >
                </mat-form-field>
        </div>      
     
    
        <div class="row">
                <mat-form-field class="row_align input-field-sec col s8 offset-s3" appearance="none">
                <label   class="login_align_sec" i18n>{{questionTwo | translate}}</label>
                <input matInput type="text" class="login_text_sec  login_align_sec"  i18n  formControlName="AnswerTwo" required>
                </mat-form-field>
        </div>

      <div class="row">
      <mat-form-field class="row_align input-field-sec col s8 offset-s3" appearance="none">
               <label  i18n class="login_align_sec" i18n>{{questionThree | translate}}</label>
               
                <input matInput type="text" class="login_text_sec  login_align_sec" i18n formControlName="AnswerThree" required>
                </mat-form-field>
      </div>
    
    </mat-card-content>
    <mat-card-actions>
        <mat-card-actions align="center">
          
          <button type="button" mat-raised-button color="primary" [disabled]="!securityForm.valid" (click)="createSecurityAnswers(securityForm.value)">{{ 'Create' | translate }}</button>
          <button type="button" mat-raised-button color="warn" (click)="Cancel()">{{ 'Cancel' | translate }}</button>
        </mat-card-actions>
      </mat-card-actions>
    </form>
    
    </mat-dialog-content>
    
    

    
    
